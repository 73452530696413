import React from 'react';

import {isSunOrHoliday} from 'feiertagejs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomTable from './CustomTable'

class ChecklistDuringTable extends CustomTable {

    constructor(props){
        super(props)
        this.lastDate = ''
    }

    createRow(row, selectionStyle, indentation, data) {
        indentation = indentation === undefined ? 0 : indentation;
        let rowStyle={}
        let date=row.datum === undefined ? '' : row.datum.substring(0,10)
        let dateAsDate = isNaN(Date.parse(row.datum)) ? undefined : new Date(row.datum)
        if (indentation === 0 && this.lastDate!==date) {
            rowStyle={borderBlockStart: '2px solid'}
            this.lastDate=date
        }
        if (dateAsDate!==undefined && isSunOrHoliday(dateAsDate,'ALL')) {
            rowStyle={...rowStyle, color: 'red'}
        }
        let bodyContent = []
        let i = 0
        this.props.collumns.forEach(collumn => {
            let content = []
            let contentstyle ={}
            if (i === 1) {
                let space = 25*indentation;
                contentstyle = {paddingLeft:space+"px", display: 'flex', alignItems: 'center', border: 'transparent'}
                if (row.subElemnts !== undefined) {
                    let icon = this.state.groupToggles[row.id] === true ? <FontAwesomeIcon icon="caret-down" size='lg'/> : <FontAwesomeIcon icon="caret-right" size='lg'/>
                    content.push(<span key='groupToggle' onClick={()=>this.toggleRow(row.id)} style={{padding: "4px"}}>{icon}</span>)
                }
            }
            content.push(collumn.cell === undefined ? row[collumn.key] : collumn.cell(row, data, indentation))
            bodyContent.push(<td style={contentstyle} key={row.key + '_' + collumn.key}>{content}</td>)
            i++
        })
        let element =
            [<tr onClick={(e) => this.onSelect(row)}
                key={row.key}
                style={row.key === this.state.selectedRowKey ? {...selectionStyle, ...rowStyle} : rowStyle}>
                {bodyContent}
            </tr>]

        if (row.subElemnts !== undefined) {
            if (this.state.groupToggles[row.id] === true) {
                row.subElemnts.forEach(subElement => {
                    element.push.apply(element, this.createRow(subElement, selectionStyle, indentation + 1))
                });
            }
        }
        return element;
    }
}

export default ChecklistDuringTable