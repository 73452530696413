import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNavigationRoute } from './actions';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Help from './Help'
import FAQ from './FAQ'


class Navigation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showHelp: false,
        }
    }

    render() {
        return (
            <Container style={{ paddingLeft: '20px', paddingRight: '20px', verticalAlign: 'center' }} fluid>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div >
                        <Button onClick={() => { this.props.dispatch(setNavigationRoute(this.props.routes.HOME)) }}>Home</Button>
                        {this.props.rights[5] !== undefined && <Button onClick={() => { this.props.dispatch(setNavigationRoute(this.props.routes.VERANSTALTUNGEN)) }}>Veranstaltungen</Button>}
                        {this.props.rights[4] !== undefined && <Button onClick={() => { this.props.dispatch(setNavigationRoute(this.props.routes.BENUTZER)) }}>Benutzer</Button>}
                        {this.props.rights[8] !== undefined && <Button onClick={() => { this.props.dispatch(setNavigationRoute(this.props.routes.CHECKLISTEN)) }}>Checklisten</Button>}
                        {this.props.rights[18] !== undefined && <Button onClick={() => { this.props.dispatch(setNavigationRoute(this.props.routes.WERBEMITTEL)) }}>Werbemittel/Präsente/Material</Button>}
                        {this.props.rights[20] !== undefined && <Button onClick={() => { this.props.dispatch(setNavigationRoute(this.props.routes.AUSRUESTUNG)) }}>Betreuer-Ausrüstung Verwanten</Button>}
                    </div>
                    <div>
                        <Button style={{}} onClick={() => { }}><FontAwesomeIcon icon="envelope-open-text" size="lg" /><span style={{ paddingLeft: '10px' }}>Feedback</span></Button>
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    FAQ
                                 </Tooltip>
                            }
                        >
                            <Button style={{}} onClick={() => this.setState({ showFAQ: true })}><FontAwesomeIcon icon="lightbulb" size="lg" /></Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    Hilfe
                                 </Tooltip>
                            }
                        >
                            <Button style={{}} onClick={() => this.setState({ showHelp: true })}><FontAwesomeIcon icon="question-circle" size="lg" /></Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    Drucken
                                 </Tooltip>
                            }
                        >
                            <Button style={{}} onClick={() => { }}><FontAwesomeIcon icon="print" size="lg" /></Button>
                        </OverlayTrigger>
                    </div>
                </div>

                <Modal
                    show={this.state.showHelp}
                    centered
                    onHide={() => this.setState({ showHelp: false })}
                    size='lg'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Hilfe
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Help/>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showFAQ}
                    centered
                    onHide={() => this.setState({ showFAQ: false })}
                    size='lg'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            FAQ
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FAQ/>
                    </Modal.Body>
                </Modal>
            </Container>


        )
    }
}

const mapStateToProps = (state) => {
    return {
        routes: state.navigation.routes,
        selectedTab: state.navigation.selectedTab,
        rights: state.userData.rights
    }
}

export default connect(mapStateToProps)(Navigation)