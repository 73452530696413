import React, { Component } from 'react';

import BackendService from '../../../BackendService.js';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import BeteiligteForm from './BeteiligteForm'

import CustomTable from '../../../CustomTable/CustomTable.js';


class NewBeteiligterForm extends Component {

    constructor(props) {
        super(props)

        this.beteiligterForm = React.createRef()

        this.state = {
            users: [],
            selected: {
                namefirma: '',
                anschrift: '',
                telefon: '',
                mobil: '',
                fax: '',
                www: '',
                email: '',
                bemerkungen: '',
                funktion: '',
                id: 0,
                va: this.props.va
            },
            showUsers: false
        }

        this.selectUser = this.selectUser.bind(this);
        this.enterUserSelectMode = this.enterUserSelectMode.bind(this);
        this.exitUserSelectMode = this.exitUserSelectMode.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        BackendService.getUsers().then(
            users => {
                users.map(user => {
                    user.id = user.benutzer_id
                    user.key = user.id
                });
                this.setState({
                    users
                })
            }
        )
    }

    selectUser(user) {
        let selection = {
            id: user.id,
            funktion: '',
            namefirma: ''+user.nachname + ' ' + user.vorname + ', ' + user.firma,
            anschrift: ''+user.strasse + ' ' + user.plz + ' ' + user.ort,
            mobil: ''+user.mobil,
            telefon: ''+user.telefon,
            fax: ''+user.fax,
            email: ''+user.email,
            www: ''+user.www,
            va: this.props.va
        }

        this.setState({
            selected: selection
        })
    }

    enterUserSelectMode() {
        this.setState(prevState => ({
            tmpSelected: prevState.selected,
            showUsers: true
        }))
    }

    exitUserSelectMode(confirm) {
        this.setState(prevState => ({
            tmpSelected: undefined,
            selected: confirm ? prevState.selected : prevState.tmpSelected,
            showUsers: false
        }))
    }

    handleSubmit(args) {
        this.props.handleNew(args)
        this.setState({
            selected: {
                id: 0,
                va: this.props.va
            },
            showUsers: false
        })
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    {this.state.showUsers === false &&
                        <Col xs={1}>
                            <Button
                                style={{ position: "absolute", bottom: 0 }}
                                onClick={this.enterUserSelectMode}
                            >
                                Users
                        </Button>
                        </Col>}
                    {this.state.showUsers === true &&
                        <Col xs={5}>
                            <Row>
                                <CustomTable
                                    collumns={[
                                        {
                                            title: 'Benutzer',
                                            key: 'nachname',
                                            cell: (row) =>
                                                <span key={row.id + '_nachname'}>{row.nachname + ', ' + row.vorname + ', ' + row.firma}</span>,
                                        }
                                    ]}
                                    data={this.state.users}
                                    onSelect={this.selectUser}
                                />
                            </Row>
                            <Row>
                                <Button onClick={() => this.exitUserSelectMode(false)}>Abbrechen</Button>
                            </Row>
                        </Col>}
                    <Col>
                        <BeteiligteForm
                            new
                            ref={this.beteiligterForm}
                            onSubmit={this.handleSubmit}
                            selected={this.state.selected}
                            disabled={this.state.showUsers}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default NewBeteiligterForm