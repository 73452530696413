import React from 'react'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import BaseForm from '../forms/BaseForm'
import BackendService from '../BackendService';

import _ from 'lodash'

class FormBenutzer extends BaseForm {

    constructor(...args) {
        super(...args);

        this.default = {
            selected: {
                ...this.default.selected,
                "benutzer_id": 0,
                "kontakt_id": 0,
                "benutzername": '',
                "dt_erstellt": null,
                "erstellt_von": 0,
                "vorname": '',
                "nachname": '',
                "kuerzel": '',
                "firma": '',
                "strasse": '',
                "plz": '',
                "ort": '',
                "email": '',
                "telefon": '',
                "mobil": '',
                "fax": '',
                "aktiv": false,
                'passwort': ''
            }
        }
        this.state = {
            ...this.default,
            groups: {
                all: [],
                preEdit: [],
                edited: [],
                selected: {},
            },
            roles: {
                all: [],
                preEdit: [],
                edited: [],
                selected: {},
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        BackendService.getGroups().then((groups) => {
            groups.map(group => {
                group.id = group.gruppe_id
                group.key = group.id
                return group
            });
            this.setState({
                groups: {
                    ...this.state.groups,
                    all: groups
                }
            })
        })

        BackendService.getRoles().then((roles) => {
            roles.map(role => {
                role.id = role.rolle_id
                role.key = role.id
                return role
            });
            this.setState({
                roles: {
                    ...this.state.roles,
                    all: roles
                }
            })
        })
    }

    reset() {
        super.reset();
        let groups = this.props.selected.gruppen
        groups.map(group => {
            group.id = group.gruppe_id
            group.key = group.id
            return group
        });
        let roles = this.props.selected.rollen
        roles.map(role => {
            role.id = role.rolle_id
            role.key = role.id
            return role
        });
        this.setState({
            groups: {
                ...this.state.groups,
                preEdit: groups,
                edited: groups
            },
            roles: {
                ...this.state.roles,
                preEdit: this.props.selected.rollen,
                edited: this.props.selected.rollen
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.props.new) {
            BackendService.createUser(this.state.selected).then(response => {
                this.props.onSubmit(response)
            })
        } else {
            let data = _.cloneDeep(this.state.selected)
            data.passwort = undefined;
            BackendService.editUser(this.state.selected).then(response => {
                this.props.onSubmit(response)
            })
        }
    }

    handleGroupAndRoleSubmit(groups, roles, user) {
        let groupAdd = _.pullAllWith(_.cloneDeep(groups.edited), groups.preEdit, (a, b) => a.id === b.id)
        let groupRemove = _.pullAllWith(_.cloneDeep(groups.preEdit), groups.edited, (a, b) => a.id === b.id)
        let roleAdd = _.pullAllWith(_.cloneDeep(roles.edited), roles.preEdit, (a, b) => a.id === b.id)
        let roleRemove = _.pullAllWith(_.cloneDeep(roles.preEdit), roles.edited, (a, b) => a.id === b.id)
    }

    handleDelete() {
        let id = this.state.selected.benutzer_id
        console.log(id)
        BackendService.deleteUser(id).then(() => {
            this.props.onDelete(id)
        })
    }

    renderRightsPanel(key, title) {
        let assigned = _.cloneDeep(this.state[key].edited)
        let available = _.cloneDeep(this.state[key].all)
        _.pullAllWith(available, assigned, (a, b) => +a.id === +b.id)
        console.log('assigned')
        console.log(assigned)
        console.log('available')
        console.log(available)


        let availableItems = available.map((container) =>
            <tr style={container.id === this.state[key].selected.id ? { backgroundColor: '#7EABD5' } : {}}
                onClick={(e) => {
                    this.setState({
                        [key]: {
                            ...this.state[key],
                            selected: container
                        }
                    })
                }}>
                <td>{container.bez}</td>
            </tr>
        )

        let assignedItems = assigned.map((container) =>
            <tr style={container.id === this.state[key].selected.id ? { backgroundColor: '#7EABD5' } : {}}
                onClick={(e) => {
                    this.setState({
                        [key]: {
                            ...this.state[key],
                            selected: container
                        }
                    })
                }}>
                <td>{container.bez}</td>
            </tr>
        )

        return (
            <Form.Row>
                <Col xs={5} >
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>verfügbare{' ' + title}</th>
                            </tr>
                        </thead>
                        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                            <tbody>
                                {availableItems}
                            </tbody>
                        </div>
                    </Table>
                </Col>

                <Col xs={2} style={{ alignSelf: 'center' }}>
                    <Row style={{ justifyContent: 'center' }}>
                        <Button disabled={this.state[key].selected === undefined || this.state[key].selected.id === undefined}
                            onClick={(e) => {
                                assigned.push(this.state[key].selected)
                                this.setState({
                                    [key]: {
                                        ...this.state[key],
                                        edited: assigned
                                    }
                                })
                            }}
                        >
                            -&gt;
                        </Button>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <Button disabled={this.state[key].selected === undefined || this.state[key].selected.id === undefined}
                            onClick={(e) => {
                                _.remove(assigned, this.state[key].selected)
                                this.setState({
                                    [key]: {
                                        ...this.state[key],
                                        edited: assigned
                                    }
                                })
                            }}
                        >
                            &lt;-
                        </Button>
                    </Row>
                </Col>

                <Col xs={5} >
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>zugewisene{' ' + title}</th>
                            </tr>
                        </thead>
                        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                            <tbody>
                                {assignedItems}
                            </tbody>
                        </div>
                    </Table>
                </Col>
            </Form.Row>
        )


    }

    render() {
        super.render()
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='vorname'>
                            <Form.Label>Vorname:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.vorname} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='kuerzel'>
                            <Form.Label>Kürzel:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.kuerzel} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='firma'>
                            <Form.Label>Firma:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.firma} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='strasse'>
                            <Form.Label>Straße:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.strasse} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='plz'>
                            <Form.Label>PLZ:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.plz} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='ort'>
                            <Form.Label>Ort:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.ort} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='www'>
                            <Form.Label>www:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.www} onChange={this.handleChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='nachname'>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.nachname} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='benutzername'>
                            <Form.Label>Benutzername</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.benutzername} onChange={this.handleChange} />
                        </Form.Group>
                        {this.props.new && <Form.Group controlId='passwort'>
                            <Form.Label>Passwort:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.PLACEHOLDER} onChange={this.handleChange} />
                        </Form.Group>}
                        {!this.props.new && <Form.Group controlId='changePW'>
                            <Col>
                                <Form.Row>
                                    <Form.Label>Passwort:</Form.Label>
                                </Form.Row>
                                <Form.Row>
                                    <Button>Passwort ändern</Button>
                                </Form.Row>
                            </Col>
                        </Form.Group>}
                        <Form.Group controlId='telefon'>
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.telefon} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='fax'>
                            <Form.Label>Fax</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.fax} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='mobil'>
                            <Form.Label>Mobil</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.mobil} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='email'>
                            <Form.Label>E-Mail</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.email} onChange={this.handleChange} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                {this.renderRightsPanel('groups', "Gruppen")}
                {this.renderRightsPanel('roles', "Sonderberechtigungen")}
                <Form.Row>
                    {!this.props.new && <Col xs='auto'>
                        <Button onClick={this.handleDelete}>Löschen</Button>
                    </Col>}
                    <Col xs='auto'>
                        <Button type='submit'>{this.props.new ? 'Anlegen' : 'Speichern'}</Button>
                    </Col>
                </Form.Row>
            </Form>
        )
    }
}

export default FormBenutzer
