import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';



class ChecklistFormDuring extends Component {

    render() {
        let disableForm = this.props.disabled
        let blockEdits = this.props.user.benutzer_id === this.props.va.leitung_id ||
            this.props.user.benutzer_id === this.props.va.leitung_id_2 ||
            this.props.user.benutzer_id === this.props.va.leitung_extern_id ||
            this.props.user.benutzer_id === this.props.va.leitung_extern_id_2
        blockEdits = blockEdits && !this.props.new
        if (this.props.selectedElement === undefined) {
            return (
                <Container fluid></Container>
            )
        }
        return (
            <Container fluid>
                <Form onSubmit={e => this.props.handleSubmit(e)}>
                    {!this.props.new && <Form.Row>
                        <Col>
                            <Form.Group as={Row} controlId="erstellt_von">
                                <Form.Label column>
                                    Ersteller:
                                </Form.Label>
                                <Col >
                                    <Form.Control plaintext readOnly value={this.props.selectedElement.erstellt_von} />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>

                            <Form.Group as={Row} controlId="aktiv">
                                <Col sm={{ span: 10 }}>
                                    <Form.Check checked={this.props.selectedElement.aktiv} label="aktiv" onChange={this.props.handleChangeCheckbox}
                                        disabled={disableForm || blockEdits} />
                                </Col>
                            </Form.Group>
                        </Col>

                    </Form.Row>}
                    <Form.Row>
                        <Col>
                            <Form.Group controlId='bez'>
                                <Form.Label>Stichwort</Form.Label>
                                <Form.Control type="text" value={this.props.selectedElement.bez} onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.bez !== '')} />
                            </Form.Group>
                            <Form.Group controlId='ort'>
                                <Form.Label>Ort</Form.Label>
                                <Form.Control type="text" value={this.props.selectedElement.ort} onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.ort !== '')} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={6}>
                            <Form.Group controlId='datum'>
                                <Form.Label>von:</Form.Label>
                                <Form.Control type="datetime-local" value={this.props.selectedElement.datum != null ? this.props.selectedElement.datum.substring(0, 19) : ""} onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.datum !== '' && this.props.origElement.datum !== null)} />
                            </Form.Group>
                            <Form.Group controlId='datum_bis'>
                                <Form.Label>bis:</Form.Label>
                                <Form.Control type="datetime-local" value={this.props.selectedElement.datum_bis != null ? this.props.selectedElement.datum_bis.substring(0, 19) : ""} onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.datum_bis !== '' && this.props.origElement.datum_bis !== null)} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId='ist_aufgabe'>
                                <Form.Label>Typ:</Form.Label>
                                <Form.Control as="select" value={this.props.selectedElement.ist_aufgabe === undefined ? false : this.props.selectedElement.ist_aufgabe}
                                    onChange={this.props.handleChange}
                                    disabled={disableForm || blockEdits}>
                                    <option key={'ist_aufgabe_true'} value={true}>Aufgabe</option>
                                    <option key={'ist_aufgabe_false'} value={false}>Programmpunkt</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='zustaendig.benutzer_id'>
                                <Form.Label>zuständig:</Form.Label>
                                <Form.Control as="select" value={this.props.selectedElement.zustaendig !== undefined ? this.props.selectedElement.zustaendig.benutzer_id : ''}
                                    onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.zustaendig !== undefined)}>
                                    {this.props.userOptions}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="aufgabe">
                                <Form.Label>Beschreibung:</Form.Label>
                                <Form.Control type="text" value={this.props.selectedElement.aufgabe} onChange={this.props.handleChange} rows="3" 
                                disabled={disableForm || (blockEdits && this.props.origElement.aufgabe !== '')} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Row}>
                            <Col>
                                {!this.props.new && <Button disabled={this.props.selectedElement.zu_element !== undefined || disableForm} onClick={(e) => this.props.handleNewSubElement(this.props.selectedElement.id)}>Unteraufgabe Hinzufügen</Button>}
                                <Button disabled={disableForm} type="submit">Speichern</Button>
                            </Col>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        va: state.vaView.selectedVA,
        user: state.userData.user
    }
}

export default connect(mapStateToProps)(ChecklistFormDuring)