import React, { Component } from 'react';
import BackendService from './BackendService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomTable from './CustomTable/CustomTable.js'


import _ from 'lodash';

class FAQ extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            edit: false,
            editorFrage: undefined,
            editorAntwort: undefined
        }

        this.refrech = this.refrech.bind(this);
        this.refrech();
    }

    refrech() {
        BackendService.getFAQ().then((faqArray) => {

            this.setState({
                faq: faqArray.map(faq => {
                    faq.key = faq.id
                    return faq
                })
            })
        })
    }

    save() {
        let faqElement = {
            ...this.state.selected,
            htmlfrage: this.state.editorFrage.getData(),
            htmlantwort: this.state.editorAntwort.getData(),
        }
        BackendService.editFAQ(faqElement).then((response) => {

        })


    }

    delete() {

    }

    render() {

        let faq = this.state.faq
        _.sortBy(faq, 'sort')

        if (this.state.faq === undefined) {
            return (<span>lade FAQ</span>)
        }
        return (
            <Container>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div>
                                <Button onClick={() => this.setState({ edit: true })} disabled={this.state.selected === undefined}>Eintrag löschen</Button>
                                <Button onClick={() => this.setState({ edit: true })} disabled={this.state.selected === undefined}>Eintrag bearbeiten</Button>
                                <Button onClick={() => this.save()}>Neuer Eintrag</Button>
                                <Button style={{}} onClick={() => { }}><FontAwesomeIcon icon="print" size="lg" /></Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '40px' }}>
                    <Col xs={5}>
                        <CustomTable
                            hideSearch
                            collumns={[
                                {
                                    title: 'FAQ',
                                    key: 'htmlfrage',
                                    cell: (row) => {
                                        return (
                                            <span dangerouslySetInnerHTML={{ __html: row.htmlfrage }} key={row.id} />
                                        )
                                    },
                                },
                            ]}
                            data={faq}
                            onSelect={(row) => {
                                this.setState({ selected: row })
                            }}
                        />
                    </Col>
                    <Col xs={7}>
                        {this.state.edit && [
                            <Row key='frage'>
                                <Col>
                                    <span>Frage:</span>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.selected.htmlfrage}
                                        config={{
                                            toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
                                        }}
                                        onInit={editor => {
                                            this.setState({ editorFrage: editor })
                                        }}
                                    />
                                </Col>
                            </Row>,
                            <Row style={{ marginTop: '30px' }} key='antwort'>
                                <Col>
                                    <span>Antwort:</span>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.selected.htmlantwort}
                                        config={{
                                            toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
                                        }}
                                        onInit={editor => {
                                            this.setState({ editorAntwort: editor })
                                        }}
                                    />
                                </Col>
                            </Row>
                        ]}

                        {!this.state.edit && [
                            <Row key='frage'>
                                <Col>
                                    <div>Frage:</div>
                                    {this.state.selected !== undefined && <div dangerouslySetInnerHTML={{ __html: this.state.selected.htmlfrage }} />}
                                </Col>
                            </Row>,
                            <Row style={{ marginTop: '30px' }} key='antwort'>
                                <Col>
                                    <div>Antwort:</div>
                                    {this.state.selected !== undefined && <div dangerouslySetInnerHTML={{ __html: this.state.selected.htmlantwort }} />}
                                </Col>
                            </Row>
                        ]}
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={() => { this.save() }}>Eintrag Speichern</Button>
                </Row>
            </Container>
        )
    }
}

export default FAQ