import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import CustomTable from '../../CustomTable/CustomTable.js';
import FormWerbemittel from './FormWerbemittel';
import BaseViewWerbemittel from './BaseViewWerbemittel';


import _ from 'lodash';

class ViewWerbemittelBestand extends BaseViewWerbemittel {

    render() {
        return (
            <div className={this.props.className}>
                <Container fluid>
                    <Row>
                        <Button onClick={this.handleNew}>Neuer Eintrag</Button>
                    </Row>
                    <Row>
                        <Col xs={8} style={{ overflow: 'scroll' }}>
                            <CustomTable
                                ref={this.table}
                                collumns={[
                                    {
                                        title: 'Art.-Nr.',
                                        key: 'artikelnummer',
                                    },
                                    {
                                        title: 'Artikel',
                                        key: 'artikel',
                                    },
                                    {
                                        title: 'Artikelausführung',
                                        key: 'ausfuerung',
                                    },
                                    {
                                        title: 'Größe HxBxT',
                                        key: 'groesse',
                                    },
                                    {
                                        title: 'Logo',
                                        key: 'logo',
                                    },
                                    {
                                        title: 'Lagerort',
                                        key: 'lagerort',
                                    },
                                    {
                                        title: 'Preis (€)',
                                        key: 'preis',
                                    },
                                    {
                                        title: 'Bild',
                                        key: 'previews',
                                        cell: (row) => {
                                            let images = this.state.images[row.key]
                                            if (images !== undefined && row.previews.length > 0) {
                                                let srcID = _.find(row.previews, (preview) => preview.mainImage).id
                                                return (
                                                    <div style={{ alignItems: "center", display: "flex", justifyContent: 'center', overflow: 'hidden' }}>
                                                        <img
                                                            src={images[srcID]} alt=""
                                                            style={{ maxHeight: "100px", maxWidth: "100px" }}
                                                        />
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                ]}
                                data={this.state.data}
                                onSelect={(row) => {
                                    this.setState({ selected: row })
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <FormWerbemittel
                                va={this.props.id}
                                disabled={this.state.selected.id === 0}
                                selected={this.state.selected}
                                refresh={this.refresh}
                                handleDelete={this.handleDelete}
                                handleEdit={this.handleEdit}
                                bestand
                            />
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

export default ViewWerbemittelBestand