import { SET_NAVIGATION_ROUTE } from '../actions/actionTypes'

const routes = {
    HOME: {
        helpname: 'Home:',
        helpID: 1,
    },
    //VERANSTALTUNGEN: 'VERANSTALTUNGEN',
    VERANSTALTUNGEN: {
        helpname: 'Veranstaltungen:',
        helpID: 3,
        GRUNDDATEN: {
            name: 'VA_GRUNDDATEN',
            helpID: 3,
        },
        BEARBEITEN: {
            EDIT_BETEILIGTE: {
                id: 0,
                name: 'VA_EDIT_BETEILIGTE',
                helpID: 3,
            },
            EDIT_PRE: {
                id: 1,
                name: 'VA_EDIT_PRE',
                helpID: 3,
            },
            EDIT_DURING: {
                id: 2,
                name: 'VA_EDIT_DURING',
                helpID: 3,
            },
            EDIT_POST: {
                id: 3,
                name: 'VA_EDIT_POST',
                helpID: 3,
            },
            EDIT_WERBEMITTEL: {
                id: 4,
                name: 'VA_EDIT_WERBEMITTEL',
                helpID: 3,
            },
            EDIT_AUSRUESTUNG: {
                id: 5,
                name: 'VA_EDIT_AUSRUESTUNG',
                helpID: 3,
            },
            helpID: 3,
        }
    },
    BENUTZER: {
        helpname: 'Benutzer:',
        helpID: 9,
    },
    CHECKLISTEN: {
        helpname: 'Checklisten:',
        helpID: 15,
    },
    WERBEMITTEL: {
        helpname: 'Werbemittel:',
        helpID: 11,
    },
    AUSRUESTUNG: {
        helpname: 'Werbemittel',
        helpID: 11,
    },
}

const initialState = {
    routes,
    selectedTab: routes.HOME
}

const navigation = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAVIGATION_ROUTE:
            return Object.assign({}, state, {
                selectedTab: action.value,
            })
        default:
            return state
    }
}

export default navigation