import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewEvents from './Views/Events/ViewEvents.js';
import styles from './Views.module.css'
import EventGrunddatenForm from './Views/Events/EventGrunddatenForm';
import EventForm from './Views/Events/EventForm';
import EditEventMainView from './Views/Events/EditEventMainView.js';
import ViewBenutzer from './Views/ViewBenutzer.js';
import ViewHome from './Views/ViewHome.js';
import ViewWerbemittelBestand from './Views/Werbemittel/ViewWerbemittelBestand.js'
import ViewAusruestungBestand from './Views/Ausruestung/ViewAusruestungBestand.js'
import CheckmasterMain from './Views/Checkmaster/CheckmasterMain.js';

class MainView extends Component {
    render() {
        let View = <div className={styles.baseView}>Empty View</div>
        let Title = this.props.selectedTab;
        switch (this.props.selectedTab) {
            case this.props.routes.HOME:
                Title = 'Home'
                View = <ViewHome className={styles.baseView} />
                break;
            case this.props.routes.VERANSTALTUNGEN:
                Title = 'Veranstaltungen'
                View = <ViewEvents className={styles.baseView} />
                break;
            case this.props.routes.VERANSTALTUNGEN.GRUNDDATEN:
                Title = 'Veranstaltungen - Grunddaten/Zugriffsrechte'
                //View = <EventGrunddatenForm className={styles.baseView} />
                View = <EventForm className={styles.baseView} />
                break;
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN:
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN.EDIT_BETEILIGTE:
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN.EDIT_PRE:
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN.EDIT_DURING:
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN.EDIT_POST:
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN.EDIT_WERBEMITTEL:
            case this.props.routes.VERANSTALTUNGEN.BEARBEITEN.EDIT_AUSRUESTUNG:
                Title = 'Veranstaltungen - Bearbeiten'
                View = <EditEventMainView className={styles.baseView} />
                break;
            case this.props.routes.BENUTZER:
                Title = 'Benutzer'
                View = <ViewBenutzer className={styles.baseView} />
                break;
            case this.props.routes.CHECKLISTEN:
                Title = 'Checklisten'
                View = <CheckmasterMain className={styles.baseView} />
                break;
            case this.props.routes.WERBEMITTEL:
                Title = 'Werbemittel/Präsente/Material'
                View = <ViewWerbemittelBestand id={0} className={styles.baseView} />
                break;
            case this.props.routes.AUSRUESTUNG:
                Title = 'Betreuer-Ausrüstung Verwanten'
                View = <ViewAusruestungBestand id={0} className={styles.baseView} />
                break;
            default:
                break;
        }
        return (
            <div className={styles.container}>
                <span className={styles.containerHeadline}>{Title}</span>
                {View}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        routes: state.navigation.routes,
        selectedTab: state.navigation.selectedTab,
        //rights: state.userData.rights,
    }
}

export default connect(mapStateToProps)(MainView)