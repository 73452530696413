import { SET_USERDATA, LOGOUT } from '../actions/actionTypes'

const initialState = {
    user: sessionStorage.getItem('userName'),
    rights: {
        1: {
            "recht_id": 1,
            "bez": "Neuanlage VA",
        },
        2: {
            "recht_id": 2,
            "bez": "Neuanlage Benutzer",
        },
        3: {
            "recht_id": 3,
            "bez": "Kostenauswertung",
        },
        4: {
            "recht_id": 4,
            "bez": "Benutzer bearbeiten",
        },
        5: {
            "recht_id": 5,
            "bez": "Veranstaltung bearbeiten",
        },
        7: {
            "recht_id": 7,
            "bez": "Login",
        },
        8: {
            "recht_id": 8,
            "bez": "Checklisten bearbeiten",
        },
        9: {
            "recht_id": 9,
            "bez": "ToDos bearbeiten",
        },
        10: {
            "recht_id": 10,
            "bez": "Rollenvergabe",
        },
        11: {
            "recht_id": 11,
            "bez": "Gruppenvergabe",
        },
        12: {
            "recht_id": 12,
            "bez": "Alle VAs sehen",
        },
        13: {
            "recht_id": 13,
            "bez": "Inaktive Checklisten-Elemente sehen",
        },
        14: {
            "recht_id": 14,
            "bez": "Excel-Upload",
        },
        15: {
            "recht_id": 15,
            "bez": "Hilfe editieren",
        },
        16: {
            "recht_id": 16,
            "bez": "Feedback sehen",
        },
        17: {
            "recht_id": 17,
            "bez": "FAQ editieren",
        },
        18: {
            "recht_id": 18,
            "bez": "Werbemittel verwalten",
        },
        19: {
            "recht_id": 19,
            "bez": "Adressbuch sehen",
        },
        20: {
            "recht_id": 20,
            "bez": "Ausruestung verwalten",
        },
    }
}

const userData = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERDATA:
            return Object.assign({}, state, {
                user: action.value.user
            })
        case LOGOUT:
            sessionStorage.clear();
            return Object.assign({}, state, {
                user: undefined
            })
        default:
            return state
    }
}

export default userData