import React, { Component } from 'react';

import BackendService from '../../../BackendService.js';

import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

import CustomTable from '../../../CustomTable/CustomTable.js'

import BeteiligteForm from './BeteiligteForm'

import _ from 'lodash';
import NewBeteiligterForm from './NewBeteiligterForm.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ViewBeteiligte extends Component {

    constructor(props) {
        super(props)
        this.refresh();

        this.state = {
            data: [],
            selected: {
                id: 0
            }
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSaveSort = this.handleSaveSort.bind(this);

        this.editForm = React.createRef()
        this.newForm = React.createRef()
        this.table = React.createRef()
    }

    refresh(callback) {
        BackendService.getBeteiligte(this.props.id).then(
            beteiligte => {
                let data = beteiligte.map(beteiligter => beteiligter = {
                    ...beteiligter,
                    key: beteiligter.id
                })

                let highestSort = 0
                let toSort = []
                data.forEach(row => {
                    if (row.sort === undefined) {
                        toSort.push(row)
                    } else {
                        if (row.sort > highestSort) {
                            highestSort = row.sort
                        }
                    }
                });
                toSort.forEach(row => {
                    highestSort++
                    row.sort = highestSort
                });

                this.setState({
                    data
                })
                if (callback !== undefined) {
                    callback()
                }
            }
        )
    }

    handleEdit(beteiligter) {
        let beteiligte = _.cloneDeep(this.state.data)
        let index = _.findIndex(beteiligte, (item) => item.id === beteiligter.id)
        beteiligte[index] = beteiligter
        this.setState({
            data: beteiligte
        })
    }

    handleNew(beteiligter) {
        let beteiligte = _.cloneDeep(this.state.data)
        beteiligte.push(beteiligter)
        this.setState({
            data: beteiligte,
            creatingNew: false,
            selected: {
                id: 0
            },
        })
    }

    handleDelete(id) {
        let beteiligte = _.cloneDeep(this.state.data)
        _.remove(beteiligte, (item) => item.id === id)
        this.setState({
            data: beteiligte,
            selected: {
                id: 0
            },
        })
        this.table.current.setSelectedRowKey(0)
    }

    sort(row, data, direction) {
        let index = _.indexOf(data, row)
        let other = data[index + direction]
        let tmpSort = other.sort
        let newData = _.cloneDeep(data)
        newData[index + direction].sort = row.sort;
        newData[index].sort = tmpSort;
        this.setState(
            {
                data: newData,
                selected: newData[index]
            }
        )
    }

    handleSaveSort() {
        let body = {
            beteiligte: this.table.current.getSortedIds()
        }
        BackendService.sortBeteiligte(this.props.id, body).then((returnValue) =>
            this.refresh()
        )
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col xs='auto'>
                        <Button onClick={() => this.setState({ creatingNew: true })}>Neuer Eintrag</Button>
                    </Col>
                    <Col xs='auto'>
                        <Button onClick={this.handleSaveSort}>Sortierung abspeichern</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <CustomTable
                            ref={this.table}
                            initialSortKey={'sort'}
                            collumns={[
                                {
                                    title: 'sort',
                                    key: 'sort',
                                    cell: (row, data) => {
                                        if (row.id === this.state.selected.id && this.table.current.state.sortedCollumn==='sort') {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <Button style={{ backgroundColor: "transparent", border: "transparent", padding: "0px" }}
                                                        onClick={() => this.sort(row, data, -1)}
                                                    ><FontAwesomeIcon icon="caret-up" size="2x" /></Button>

                                                    <Button style={{ backgroundColor: "transparent", border: "transparent", padding: "0px" }}
                                                        onClick={() => this.sort(row, data, 1)}
                                                    ><FontAwesomeIcon icon="caret-down" size="2x" /></Button>
                                                </div>
                                            )
                                        }
                                    },
                                },
                                {
                                    title: 'Funktion',
                                    key: 'funktion',
                                },
                                {
                                    title: 'Vor- und Nachname / Firma',
                                    key: 'namefirma',
                                },
                                {
                                    title: 'Anschrift',
                                    key: 'anschrift',
                                },
                            ]}
                            data={this.state.data}
                            onSelect={(row) => {
                                this.setState({ selected: row })
                            }}
                        />
                    </Col>
                    <Col xs={4}>
                        <BeteiligteForm
                            disabled={this.state.selected.id === 0}
                            selected={this.state.selected}
                            ref={this.editForm}
                            onSubmit={this.handleEdit}
                            onDelete={this.handleDelete} />
                    </Col>
                </Row>
                <Modal
                    size="xl"
                    show={this.state.creatingNew}
                    centered
                    onHide={() => this.setState({ creatingNew: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Neuer Eintrag
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NewBeteiligterForm
                            va={this.props.id}
                            handleNew={this.handleNew} />
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        id: state.vaView.selectedVA
    }
}

export default connect(mapStateToProps)(ViewBeteiligte)