import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { connect } from 'react-redux';
import { setNavigationRoute } from '../../actions';
import ViewBeteiligte from './Beteiligte/ViewBeteiligte';
import ChecklistViewDuring from './Checklisten/ChecklistViewDuring';
import ChecklistViewPrePost from './Checklisten/ChecklistViewPrePost';
import ViewWerbemittel from './Werbemittel/ViewWerbemittel';
import ViewAusruestung from './Ausruestung/ViewAusruestung';
import _ from 'lodash';

//key => this.props.dispatch(setNavigationRoute(key)) this.props.dispatch(setNavigationRoute   _.find(this.props.routes,{id: key})
class EditEventMainView extends Component {

    getRoute(key){
        console.log(key)
        let route=this.props.selectedTab;
        for (let objkey in this.props.routes){
            let newroute = this.props.routes[objkey]
            if (+newroute.id===+key) {
                console.log(newroute)
                return newroute
            }
        }
        return route
    }

    render() {
        return (
            <div className={this.props.className}>
                <Tabs activeKey={this.props.selectedTab.id}
                    onSelect={key => this.props.dispatch(setNavigationRoute(this.getRoute(key)))}>
                    <Tab eventKey={this.props.routes.EDIT_BETEILIGTE.id} title="Beteiligte">
                        <ViewBeteiligte />
                    </Tab>
                    <Tab eventKey={this.props.routes.EDIT_PRE.id} title="Vorbereitung">
                        <ChecklistViewPrePost listTyp={1} />
                    </Tab>
                    <Tab eventKey={this.props.routes.EDIT_DURING.id} title="Durchführung">
                        <ChecklistViewDuring listTyp={3} />
                    </Tab>
                    <Tab eventKey={this.props.routes.EDIT_POST.id} title="Nachbereitung">
                        <ChecklistViewPrePost listTyp={4} />
                    </Tab>
                    <Tab eventKey={this.props.routes.EDIT_WERBEMITTEL.id} title="Werbemittel/Präsente/Material">
                        <ViewWerbemittel />
                    </Tab>
                    <Tab eventKey={this.props.routes.EDIT_AUSRUESTUNG.id} title="Betreuer-Ausrüstung">
                        <ViewAusruestung />
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        routes: state.navigation.routes.VERANSTALTUNGEN.BEARBEITEN,
        selectedTab: state.navigation.selectedTab
    }
}

export default connect(mapStateToPropsSeries)(EditEventMainView)