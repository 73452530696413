import React, { Component } from 'react';

import BackendService from '../../BackendService.js';
import _ from 'lodash';

class BaseViewAusruestung extends Component {

    constructor(props) {
        super(props);
        this.refresh();

        this.state = {
            data: [],
            selected: {
                id: 0
            },
            images: {}
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.refresh = this.refresh.bind(this);

        this.table = React.createRef();
    }

    refresh() {
        return new Promise((resolve) => {
            BackendService.getAusruestungByVA(this.props.id).then((data) => {
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    element.key = element.id;
                    if (element.previews.length===0) {
                        continue;
                    }
                    for (let h = 0; h < element.previews.length; h++) {
                        let preview = element.previews[h];
                        if (preview.mainImage) {
                            BackendService.getAusruestungFile(preview.id).then((data) => {
                                let prevImages = _.cloneDeep(this.state.images);
                                this.setDeepChild(prevImages, [element.key, preview.id], data);
                                this.setState({
                                    images: prevImages
                                });
                            });
                            break;
                        }                        
                    }
                }

                let selected = this.state.selected;
                if (selected.id !== 0) {
                    selected = _.find(data, (item) => item.id === selected.id);
                }
                this.setState({
                    data: data,
                    selected
                });
                resolve();
            });
        });
    }

    setDeepChild(obj, path, value) {
        let ret = obj;
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {};
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value;
        return ret;
    }

    handleEdit(eq) {
        eq.key = eq.id;
        let equipment = _.cloneDeep(this.state.data);
        let index = _.findIndex(equipment, (item) => item.id === eq.id);
        equipment[index] = eq;
        this.setState({
            data: equipment
        });
    }

    handleNew() {
        BackendService.createAusruestung({artikel: 'Neuer Eintrag', beschreibunglang: '', artikelnummer: '', lagerort: '', lieferant: '', bemerkung: '', veranstaltungid: this.props.id,  ausfuerung: '', groesse: '', logo: '', owner: '', beschaffer: ''}).then((eq) => {
            let equipment = _.cloneDeep(this.state.data);
            eq.key=eq.id;
            equipment.push(eq);
            this.setState({
                data: equipment,
                selected: eq
            });
            this.table.current.setSelectedRowKey(eq.id);
        });
    }

    handleDelete(id) {
        let equipment = _.cloneDeep(this.state.data) ;
        _.remove(equipment, (item) => item.id === id);
        this.setState({
            data: equipment,
            selected: {
                id: 0
            },
        });
        this.table.current.setSelectedRowKey(0);
    }

    render() {
        return;
    }
}

export default BaseViewAusruestung;