import React from 'react';
import Form from 'react-bootstrap/Form'

import Button from 'react-bootstrap/Button';
import BackendService from '../../../BackendService';
import Base64Converter from '../../../Base64Converter';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import BaseForm from '../../../forms/BaseForm'


class BeteiligteForm extends BaseForm {

    constructor(...args) {
        super(...args);

        this.default = {
            selected: {
                ...this.default.selected,
                "namefirma": '',
                "anschrift": '',
                "telefon": '',
                "mobil": '',
                "fax": '',
                "www": '',
                "email": '',
                "bemerkungen": '',
                "va": this.props.selected.va,
                "funktion": '',
                "erstellt_von": '',
                "dt_erstellt": null,
            }
        }

        this.state = this.default;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.onEditImage = this.onEditImage.bind(this);
        this.reset = this.reset.bind(this);
    }

    handleImage(beteiligter) {
        return new Promise((resolve) => {
            if (this.state.newImage !== undefined) {
                let deleteFile = new Promise((resolve) => {
                    if (this.state.image !== undefined) {
                        BackendService.deleteBeteiligterFile(beteiligter.id, { id: beteiligter.id }).then(response => {
                            resolve();
                        })
                    } else {
                        resolve();
                    }
                })
                deleteFile.then(() => {
                    let base64 = this.state.newImage.replace(/^data:.+;base64,/, '')
                    let data = {
                        "name": 'beteiligter_'+beteiligter.id + '_preview',
                        "content": base64
                    }
                    BackendService.createBeteiligterFile(beteiligter.id, data).then(() => {
                        resolve()
                    })
                })
            } else {
                resolve()
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.props.new) {
            BackendService.createBeteiligter(this.state.selected.va, this.state.selected).then(beteiligter => {
                this.handleImage(beteiligter).then(() => {
                    BackendService.getBeteiligterById(beteiligter.id).then(
                        beteiligter => {
                            if (this.props.onSubmit !== undefined) {
                                this.props.onSubmit(beteiligter)
                            }
                        })
                })
            })
        } else {
            this.handleImage(this.state.selected).then(() => {
                BackendService.editBeteiligter(this.state.selected).then(
                    beteiligter => {
                        if (this.props.onSubmit !== undefined) {
                            this.props.onSubmit(beteiligter)
                        }
                    })
            })
        }
    }

    handleDelete() {
        let id = this.state.selected.id
        BackendService.deleteBeteiligter(this.state.selected).then(
            data => {
                if (this.props.onDelete !== undefined) {
                    this.props.onDelete(id)
                }
            })
    }

    onEditImage(e) {
        let file = e.target.files[0];
        Base64Converter.toBase64(file).then((base64) => {
            this.setState({
                newImage: base64
            })
        })
    }

    reset() {
        console.log("reset")
        super.reset();
        this.setState({
            file: '',
            image: undefined,
            newImage: undefined
        })

        if (this.props.selected.preview !== undefined) {
            let id = this.props.selected.id;
            BackendService.getBeteiligterFile(this.props.selected.id).then((data) => {
                if (this.state.selected.id===id) {
                    this.setState({
                        image: data
                    })
                }
            })
        }

    }

    render() {
        super.render();
        return (
            <Form onSubmit={e => this.handleSubmit(e)}>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='namefirma'>
                            <Form.Label>Vor- und Nachname / Firma:</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.namefirma} onChange={this.handleChange} rows={4} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='anschrift'>
                            <Form.Label>Anschrift:</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.anschrift} onChange={this.handleChange} rows={4} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='funktion'>
                            <Form.Label>Funktion:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.funktion} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='mobil'>
                            <Form.Label>Mobil:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.mobil} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Group controlId='bemerkungen'>
                            <Form.Label>Bemerkung:</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.bemerkungen} onChange={this.handleChange} rows={8} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='telefon'>
                            <Form.Label>Telefon +49 (0)123 456789:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.telefon} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        {/* <Form.Group controlId='fax'>
                            <Form.Label>Fax:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.fax} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group> */}
                        <Form.Group controlId='image'>
                            <Form.Label>Foto:</Form.Label>
                            <Row style={{ justifyContent: 'center', padding: '10px' }}>
                                {/* src={this.state.selected.preview !== undefined ? 'http://127.0.0.1:8080/api' + '/beteiligte/' + this.state.selected.id + '/preview' : ''} */}
                                { (this.state.newImage!==undefined || this.state.image!==undefined) &&
                                    <img
                                    src={this.state.newImage !== undefined ? this.state.newImage : this.state.image}
                                    style={{ maxWidth: '100%', maxHeight: '12em' }}
                                    alt='preview' />
                                }
                            </Row>
                            <Form.Control type="file" accept="image/png, image/jpeg" onChange={this.onEditImage} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='email'>
                            <Form.Label>E-Mail:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.email} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='www'>
                            <Form.Label>www:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.www} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {!this.props.new &&
                            <Button onClick={this.handleDelete}>Löschen</Button>
                        }
                    </Col>
                    <Col>
                        <Button type="submit">Speichern</Button>
                    </Col>
                </Form.Row>
            </Form>
        )
    }
}

export default BeteiligteForm