import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import BackendService from '../../BackendService.js';
import { connect } from 'react-redux';
import { setNavigationRoute } from '../../actions';
import _ from 'lodash';


import Table from 'react-bootstrap/Table';



class EventForm extends Component {
    constructor(...args) {
        super(...args);

        this.newVA = {
            "dt_erstellt": "",
            "bez": "",
            "ort": "",
            "anz_teilnehmer": 0,
            "anz_teilnehmer_vertrieb": 0,
            "anz_teilnehmer_mkt3": 0,
            "budget": 0.0,
            "leitung_id": 0,
            "leitung_extern_id": 0,
            "aktiv": true,
            "art": {
                "id": 0,
                "bez": "",
                "aktiv": true,
                "sort": 0
            },
            "typ": {
                "id": 0,
                "bez": "",
                "aktiv": true,
                "sort": 0
            }
        }

        this.state = {
            selectedUser: {
                benutzer_id: 0
            },
            assignedUsers: [],
            preAssignedUsers: [],
            VA: this.newVA
        };
        let promises = []

        if (!this.props.new) {
            promises.push(BackendService.getVeranstaltungen(this.props.id).then(
                VA => {
                    this.setState({
                        VA
                    })
                }
            ))
            promises.push(BackendService.getVAUsers(this.props.id).then(
                users => {
                    this.setState({
                        preAssignedUsers: users,
                        assignedUsers: users
                    })
                }
            ))
        }
        promises.push(BackendService.getUsers().then(
            users => {
                this.setState({
                    users,
                    unassignedUsers: users
                })
            }
        ))
        promises.push(BackendService.getVAArten().then(
            _arten => {
                let arten = _.filter(_arten, 'aktiv')
                this.setState({
                    arten,
                })
            }
        ))
        promises.push(BackendService.getVATypen().then(
            _typen => {
                let typen = _.filter(_typen, 'aktiv')
                this.setState({
                    typen,
                })
            }
        ))

        Promise.all(promises).then(
            () => this.setState({
                requestsDone: true
            })
        )


    }

    handleSubmit(event) {
        event.preventDefault();
        let va = this.props.id
        let editedVA = { ...this.state.VA };

        if (editedVA.typ.id === 0) {
            editedVA.typ = this.state.typen[0].id
            console.log('typ')
        }
        if (editedVA.art.id === 0) {
            editedVA.art = this.state.arten[0].id
            console.log('art')
        }

        editedVA.von = editedVA.von != null ? editedVA.von : null
        editedVA.bis = editedVA.bis != null ? editedVA.bis : null
        editedVA.art = editedVA.art.id === undefined ? editedVA.art : editedVA.art.id;
        editedVA.typ = editedVA.typ.id === undefined ? editedVA.typ : editedVA.typ.id;
        if (!this.props.new) {
            BackendService.editVeranstaltungen(editedVA);
            this.handleUsers(va);
            this.props.dispatch(setNavigationRoute(this.props.routes))
        } else {
            BackendService.createVeranstaltungen(editedVA).then((newVA) => {
                this.handleUsers(newVA.id).then(() => {
                    this.setState(
                        {
                            selectedUser: {
                                benutzer_id: 0
                            },
                            assignedUsers: [],
                            preAssignedUsers: [],
                            VA: this.newVA
                        }
                    )
                })
                if (this.props.onNewCallback !== undefined) {
                    this.props.onNewCallback(newVA)
                }
            })
        }

    }

    handleUsers(va) {
        let toAssign = []
        let toDelete = []

        toAssign = _.differenceWith(this.state.assignedUsers, this.state.preAssignedUsers, (a, b) => { return a.benutzer.benutzer_id === b.benutzer.benutzer_id });
        toDelete = _.differenceWith(this.state.preAssignedUsers, this.state.assignedUsers, (a, b) => { return a.benutzer.benutzer_id === b.benutzer.benutzer_id });

        toAssign.forEach(element => {
            return BackendService.createVAUser(va, element.benutzer.benutzer_id)
        });
        toDelete.forEach(element => {
            return BackendService.deleteVAUser(va, element.benutzer.benutzer_id)
        });
    }

    handleChange = e => {
        let key = e.target.id;
        let value = e.target.value
        this.setState(prevState => ({
            VA: {
                ...prevState.VA,
                [key]: value
            }
        }))
    };

    handleChangeCheckbox = e => {
        let key = e.target.id;
        let value = e.target.checked === true ? true : false
        this.setState(prevState => ({
            VA: {
                ...prevState.VA,
                [key]: value
            }
        }))
    };

    addUser(user) {
        let aUsers = _.cloneDeep(this.state.assignedUsers);
        aUsers.push({ iD: 0, benutzer: user, va: this.props.id })
        this.setState(prevState => ({
            assignedUsers: aUsers,
            selectedUser: {
                ...prevState.selectedUser,
                isAssigned: true
            }
        }))
        //BackendService.createVAUser(this.props.id, this.state.selectedUser.benutzer_id)
    }

    removeUser(user) {
        let aUsers = _.remove(this.state.assignedUsers, (u) => u.benutzer.benutzer_id !== user.benutzer_id)
        this.setState(prevState => ({
            assignedUsers: aUsers,
            selectedUser: {
                ...prevState.selectedUser,
                isAssigned: false
            }
        }))
        //BackendService.deleteVAUser(this.props.id, this.state.selectedUser.benutzer_id)
    }

    render() {
        if (this.state.requestsDone !== true) {
            return <div className={this.props.className}>Loading</div>
        }

        let userOptions = undefined;
        if (this.state.users !== undefined) {
            userOptions = [<option key={0} value={0} />]
            this.state.users.forEach(user => {
                userOptions.push(<option key={user.benutzer_id} value={user.benutzer_id}>{user.nachname}, {user.vorname} ({user.kuerzel})</option>)
            });
        }

        let artOptions = undefined;
        if (this.state.arten !== undefined) {
            artOptions = []
            this.state.arten.forEach(art => {
                if (art.aktiv) {
                    artOptions.push(<option key={art.id} value={art.id}>{art.bez}</option>)
                }
            });
        }

        let typOptions = undefined;
        if (this.state.typen !== undefined) {
            typOptions = []
            this.state.typen.forEach(typ => {
                if (typ.aktiv) {
                    typOptions.push(<option key={typ.id} value={typ.id}>{typ.bez}</option>)
                }
            });
        }

        let unassignedUsers = <span>loading</span>
        let assignedUsers = <span>loading</span>
        if (this.state.unassignedUsers !== undefined && this.state.assignedUsers !== undefined) {
            let unassignedU = _.cloneDeep(this.state.unassignedUsers)
            let assignedU = _.cloneDeep(this.state.assignedUsers)

            assignedU = _.remove(
                unassignedU,
                (a) => {
                    let filtered = (_.find(
                        assignedU,
                        (b) => {
                            return b.benutzer.benutzer_id === a.benutzer_id;
                        }
                    ));
                    return filtered === undefined;
                }
            )

            assignedUsers = unassignedU.map((user) =>
                <tr style={user.benutzer_id === this.state.selectedUser.benutzer_id ? { backgroundColor: '#7EABD5' } : {}}
                    onClick={(e) => {
                        this.setState({
                            selectedUser: {
                                ...user,
                                isAssigned: true
                            }
                        })
                    }}>
                    <td>{user.nachname + ', ' + user.vorname + ' (' + user.kuerzel + ')'}</td>
                </tr>
            )

            unassignedUsers = assignedU.map((user) =>
                <tr style={user.benutzer_id === this.state.selectedUser.benutzer_id ? { backgroundColor: '#7EABD5' } : {}}
                    onClick={(e) => {
                        this.setState({
                            selectedUser: {
                                ...user,
                                isAssigned: false
                            }
                        })
                    }}>
                    <td>{user.nachname + ', ' + user.vorname + ' (' + user.kuerzel + ')'}</td>
                </tr>
            )
        }

        return (
            <div className={this.props.className} >
                <Container fluid>
                    <h6>{this.props.new ? 'Neue Veranstaltung' : 'Grunddaten und Zugriffsrechte bearbeiten'}</h6>
                    <Form onSubmit={e => this.handleSubmit(e)}>


                        <Form.Row>
                            <Col xs={7}>
                                <Form.Group as={Row} controlId="aktiv">
                                    <Col sm={{ span: 10 }}>
                                        <Form.Check checked={this.state.VA.aktiv} label="aktiv" onChange={this.handleChangeCheckbox} />
                                    </Col>
                                </Form.Group>
                                <Form.Row>
                                    <Col xs={9}>
                                        <Form.Group controlId='bez'>
                                            <Form.Label>Name der Veranstaltung</Form.Label>
                                            <Form.Control type="text" placeholder="Veranstaltungsname" value={this.state.VA.bez} onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group controlId='ort'>
                                            <Form.Label>Ort:</Form.Label>
                                            <Form.Control type="text" placeholder="Ort" value={this.state.VA.ort} onChange={this.handleChange} />
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId='von'>
                                                    <Form.Label>Veranstaltungsbeginn:</Form.Label>
                                                    <Form.Control type="date" value={this.state.VA.von != null ? this.state.VA.von.substring(0, 10) : null} onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId='bis'>
                                                    <Form.Label>Veranstaltungsende:</Form.Label>
                                                    <Form.Control type="date" value={this.state.VA.bis != null ? this.state.VA.bis.substring(0, 10) : null} onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId='budget'>
                                                    <Form.Label>Budget in €:</Form.Label>
                                                    <Form.Control type="currency" value={this.state.VA.budget} onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId='anz_teilnehmer'>
                                            <Form.Label>PAX KUNDEN</Form.Label>
                                            <Form.Control type="number" value={this.state.VA.anz_teilnehmer} onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group controlId='anz_teilnehmer_vertrieb'>
                                            <Form.Label>PAX BUD</Form.Label>
                                            <Form.Control type="number" value={this.state.VA.anz_teilnehmer_vertrieb} onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group controlId='anz_teilnehmer_mkt3'>
                                            <Form.Label>PAX BUD SC</Form.Label>
                                            <Form.Control type="number" value={this.state.VA.anz_teilnehmer_mkt3} onChange={this.handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId='typ'>
                                            <Form.Label>Veranstaltungstyp</Form.Label>
                                            <Form.Control as="select" value={this.state.VA.typ.id} onChange={this.handleChange} disabled={typOptions === undefined}>
                                                {typOptions}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='art'>
                                            <Form.Label>Veranstaltungsart</Form.Label>
                                            <Form.Control as="select" value={this.state.VA.art.id} onChange={this.handleChange} disabled={artOptions === undefined}>
                                                {artOptions}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId='leitung_id'>
                                            <Form.Label>Projektleitung (inter, zugriffsberechtigt)</Form.Label>
                                            <Form.Control as="select" value={this.state.VA.leitung_id} onChange={this.handleChange} disabled={userOptions === undefined}>
                                                {userOptions}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='leitung_extern_id'>
                                            <Form.Label>Projektleitung (extern, zugriffsberechtigt)</Form.Label>
                                            <Form.Control as="select" value={this.state.VA.leitung_extern_id} onChange={this.handleChange} disabled={userOptions === undefined}>
                                                {userOptions}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Col>
                            <Col>
                                <Form.Row>
                                    <Col xs={5} >
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Verfügbare Nutzer</th>
                                                </tr>
                                            </thead>
                                            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                                <tbody>
                                                    {unassignedUsers}
                                                </tbody>
                                            </div>
                                        </Table>
                                    </Col>
                                    <Col xs={2} style={{ alignSelf: 'center' }}>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <Button disabled={this.state.selectedUser.isAssigned === undefined || this.state.selectedUser.isAssigned === true}
                                                onClick={(e) => this.addUser(this.state.selectedUser)}>-&gt;</Button>
                                        </Row>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <Button disabled={this.state.selectedUser.isAssigned === undefined || this.state.selectedUser.isAssigned === false}
                                                onClick={(e) => this.removeUser(this.state.selectedUser)}>&lt;-</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={5} >
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>zugeordnete Personen</th>
                                                </tr>
                                            </thead>
                                            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                                <tbody>
                                                    {assignedUsers}
                                                </tbody>
                                            </div>
                                        </Table>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                        <Button type="submit">{this.props.new ? "Veranstaltung anlegen" : "Daten speichern"}</Button>
                    </Form>
                </Container>
            </div>
        )
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        routes: state.navigation.routes.VERANSTALTUNGEN,
        id: state.vaView.selectedVA
    }
}

export default connect(mapStateToPropsSeries)(EventForm)