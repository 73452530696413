import { Component } from 'react';

import BackendService from '../../../BackendService.js';

import _ from 'lodash';

class ChecklistView extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.setState = this.setState.bind(this);

        BackendService.getVAUsers(this.props.id).then(
            users => {
                this.setState({
                    users
                })
            }
        )

        this.refresh();
    }

    refresh(callback) {
        BackendService.getChecklistElements(this.props.id, this.props.listTyp).then(
            clItems => {
                clItems.forEach(item => {
                    item.key = item.id
                    item.zustaendig = item.zustaendig === undefined ? { benutzer_id: '' } : item.zustaendig
                    if (item.datum_bis !== undefined && item.datum_bis < item.datum) {
                        let tmp = item.datum;
                        item.datum = item.datum_bis
                        item.datum_bis = tmp
                    }
                })

                let data = this.sortClItems(clItems)

                this.setState({
                    checklistItems: data,
                    groupedChecklistItems: this.groupCLItems(_.cloneDeep(clItems)),
                    loading: false
                })
                if (callback !== undefined) {
                    callback()
                }
            }
        )
    }

    sortClItems(clItems) {
        let data = _.sortBy(clItems, ['datum', 'sort']);
        let highestSort = 0
        data.forEach(row => {
            row.sort = highestSort++
        });

        return data
    }

    groupCLItems(checklist) {
        let toGroup = _.filter(checklist, (element) => element.zu_element !== undefined)
        checklist = _.pullAll(checklist, toGroup)
        toGroup.forEach(element => {
            let parent = _.find(checklist, (item) => item.id === element.zu_element)
            if (parent === undefined) {
                return;
            }
            if (parent.subElemnts === undefined) {
                parent.subElemnts = []
            }
            parent.subElemnts.push(element)
        });
        return checklist
    }

    handleSelect(element) {
        let selected = _.cloneDeep(element)
        selected.bez = selected.bez === undefined ? "" : selected.bez
        selected.aufgabe = selected.aufgabe === undefined ? "" : selected.aufgabe
        this.setState({
            selectedElement: selected,
            origElement: selected,
            bemerkungen: []
        })
        BackendService.getBemerkungen(selected.id).then(
            bemerkungen => {
                bemerkungen.map(bemerkung => bemerkung.key = bemerkung.id)
                this.setState({
                    bemerkungen
                })
            }
        )
    }

    handleChange = (e, target) => {
        let keys = e.target.id.split('.');
        let value = e.target.value
        let prevSelected = _.cloneDeep(this.state[target])
        this.setDeepChild(prevSelected, keys, value)
        this.setState(prevState => (
            {
                [target]: prevSelected
            }))
    };

    handleChangeCheckbox = (e, target) => {
        let key = e.target.id;
        let value = e.target.checked === true ? true : false
        this.setState(prevState => ({
            [target]: {
                ...prevState[target],
                [key]: value
            }
        }))
    };

    setDeepChild(obj, path, value) {
        let ret = obj
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {}
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value
        return ret;
    }

    sort(row, clItems, direction) {
        let tmpIndex = _.indexOf(clItems, row)
        if ((tmpIndex===clItems.length-1 && direction>0) || (tmpIndex===0 && direction<0)) {
            return
        }
        let index = _.findIndex(clItems,(clItem) => clItem.id === row.id)
        let otherIndex = _.findIndex(clItems,(clItem) => clItem.id === clItems[tmpIndex + direction].id)
        console.log(index)
        console.log(otherIndex)

        if (clItems[tmpIndex + direction].datum !== row.datum) {
            return;
        }
        let tmpSort = clItems[otherIndex].sort
        let newData = _.cloneDeep(clItems)
        newData[otherIndex].sort = row.sort;
        newData[index].sort = tmpSort;

        this.setState({
            checklistItems: newData,
            groupedChecklistItems: this.groupCLItems(_.cloneDeep(newData)),
            selectedElement: newData[index]
        })
    }

    handleSaveSort() {

    }
}

export default ChecklistView