import React from 'react';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import BackendService from '../../BackendService';
import Base64Converter from '../../Base64Converter';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal'

import BaseForm from '../../forms/BaseForm';
import _ from 'lodash';


class FormAusruestung extends BaseForm {

    constructor(...args) {
        super(...args);

        this.default = {
            selected: {
                ...this.default.selected,
                "artikelnummer": '',
                "beschreibungkurz": '',
                "beschreibunglang": '',
                "lagerort": '',
                "lieferant": '',
                "preis": '',
                "angeschafftdatum": '',
                "veranstaltungid": this.props.va,
                "bemerkung": '',
                stueckzahl: ''
            },
            images: [],
            newImage: '',
            showImageModal: false,
            imageModalImage: {},
        };

        this.state = this.default;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onEditImage = this.onEditImage.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);

        this.leftCol = React.createRef();
    }

    handleSubmit(event) {
        event.preventDefault();
        let data =
        {
            id: this.state.selected.id,
            artikelnummer: this.state.selected.artikelnummer,
            beschreibungkurz: this.state.selected.beschreibungkurz,
            beschreibunglang: this.state.selected.beschreibunglang,
            lagerort: this.state.selected.lagerort,
            lieferant: this.state.selected.lieferant,
            angeschafftdatum: this.state.selected.angeschafftdatum,
            veranstaltungid: this.state.selected.veranstaltungid,
            bemerkung: this.state.selected.bemerkung,
            stueckzahl: this.state.selected.stueckzahl === '' ? null : this.state.selected.stueckzahl,
            preis: this.state.selected.preis === '' ? null : this.state.selected.preis,
            artikel: '',
            ausfuerung: '',
            groesse: '',
            logo: '',
            owner: '',
            beschaffer: '',
            angelegt_von: '',
        };
        BackendService.editAusruestung(data).then((eq) => {
            if (this.props.handleEdit !== undefined) {
                this.props.handleEdit(eq);
            }
        });
    }

    handleDelete() {
        let id = this.state.selected.id;
        BackendService.deleteAusruestung({ id }).then(() => {
            this.setState(
                {
                    ...this.default
                }
            );
            if (this.props.handleDelete !== undefined) {
                this.props.handleDelete(id);
            }
        });
    }

    onEditImage(e) {
        let file = e.target.files[0];
        Base64Converter.toBase64(file).then((base64) => {
            this.setState({
                newImage: base64
            });
        });
    }

    handleImageUpload() {
        let base64 = this.state.newImage.replace(/^data:.+;base64,/, '');
        let data = {
            "name": 'ausruestung_' + this.state.selected.id + '_preview',
            "content": base64
        };
        BackendService.createAusruestungFile(this.state.selected.id, data).then(() => {
            this.props.refresh();
        });
    }

    reset() {
        super.reset();
        this.setState({
            images: [],
            newImage: ''
        });
        if (this.props.selected.previews !== undefined) {
            this.props.selected.previews.forEach(preview => {
                BackendService.getAusruestungFile(preview.id).then((data) => {
                    let images = _.cloneDeep(this.state.images);
                    images.push({ preview, data });
                    this.setState({
                        images
                    });
                });
            });
        }
    }

    renderImages() {
        let images = [];
        if (this.state.newImage === '') {
            this.state.images.forEach(image => {
                images.push(
                    <img src={image.data} alt=""
                        style={{ maxWidth: "100%", maxHeight: '100%', flex: 0, padding: '5px' }}
                        onClick={() => {
                            this.setState({
                                showImageModal: true,
                                imageModalImage: image
                            })
                        }}
                    />
                )
            });

        } else {
            images.push(
                <img src={this.state.newImage} alt=""
                    style={{ maxWidth: "100%", maxHeight: '100%', flex: 0, padding: '5px' }} />
            )
        }

        return images
    }

    render() {
        super.render();

        return (
            <Form onSubmit={e => this.handleSubmit(e)}>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='artikel'>
                            <Form.Label>Artikel:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.artikel} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Group controlId='ausfuerung'>
                            <Form.Label>Artikelausführung:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.ausfuerung} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Row>
                            <Col>
                                <Form.Group controlId='groesse'>
                                    <Form.Label>Größe:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.groesse} onChange={this.handleChange} disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='logo'>
                                    <Form.Label>Logo:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.logo} onChange={this.handleChange} disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group controlId='beschreibunglang'>
                            <Form.Label>Beschreibung (lang)(legacy):</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.beschreibunglang} rows={3} disabled={true} readOnly />
                        </Form.Group>
                        <Form.Row>
                            <Col>
                                <Form.Group controlId='owner'>
                                    <Form.Label>Artikel-Owner:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.owner} onChange={this.handleChange} disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='beschaffer'>
                                    <Form.Label>Beschaffer:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.beschaffer} onChange={this.handleChange} disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='angelegt_von'>
                                    <Form.Label>angelegt von:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.angelegt_von} disabled={true} readOnly />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Group controlId='bemerkung'>
                            <Form.Label>Bemerkung:</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.bemerkung} onChange={this.handleChange} rows={2} disabled={this.props.disabled} />
                        </Form.Group>
                        {this.props.bestand &&
                            <Form.Group controlId='lieferant'>
                                <Form.Label>Lieferant:</Form.Label>
                                <Form.Control type="text" value={this.state.selected.lieferant} onChange={this.handleChange} disabled={this.props.disabled} />
                            </Form.Group>}

                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col ref={this.leftCol}>
                        <Form.Group controlId='lagerort'>
                            <Form.Label>Lagerort:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.lagerort} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Group controlId='artikelnummer'>
                            <Form.Label>Artikelnummer:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.artikelnummer} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='preis'>
                            <Form.Label>Preis (€):</Form.Label>
                            <Form.Control type="number" value={this.state.selected.preis} step="0.01" min="0" onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        {!this.props.bestand &&
                            <Form.Group controlId='stueckzahl'>
                                <Form.Label>Stückzahl:</Form.Label>
                                <Form.Control type="number" value={this.state.selected.stueckzahl} step="0.01" min="0" onChange={this.handleChange} disabled={this.props.disabled} />
                            </Form.Group>}
                        {this.props.bestand &&
                            <Form.Group controlId='angeschafftdatum'>
                                <Form.Label>Angeschafft am:</Form.Label>
                                <Form.Control type="text" value={this.state.selected.angeschafftdatum} onChange={this.handleChange} disabled={this.props.disabled} />
                            </Form.Group>}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Label>Bilder:</Form.Label>

                    <div style={{ display: 'flex', width: '-webkit-fill-available', outlineStyle: 'solid', outlineWidth: '1px', outlineColor: '#cccccc', maxHeight: '200px', overflowX: 'scroll' }}>
                        {this.renderImages()}
                    </div>
                    {this.state.newImage === '' ?
                        <Form.Control key='selectImage' type="file" accept="image/png, image/jpeg" onChange={this.onEditImage} disabled={this.props.disabled} />
                        :
                        <Row>
                            <Col>
                                <Button onClick={(e) => { this.setState({ newImage: '' }) }}>Abbrechen</Button>
                            </Col>
                            <Col>
                                <Button onClick={this.handleImageUpload}>Hochladen</Button>
                            </Col>
                        </Row>
                    }
                </Form.Row>
                <Form.Row style={{ paddingTop: '40px' }}>
                    <Col>
                        {!this.props.selectionOnly &&
                            <Button disabled={this.props.disabled} onClick={this.handleDelete}>Löschen</Button>
                        }
                    </Col>
                    <Col>
                        {!this.props.selectionOnly &&
                            <Button disabled={this.props.disabled} type="submit">Speichern</Button>
                        }
                        {this.props.selectionOnly &&
                            <Button onClick={() => this.props.onCopy()}>in Packlise kopieren</Button>
                        }
                    </Col>
                </Form.Row>


                <Modal
                    show={this.state.showImageModal}
                    size="lg"
                    centered
                    onHide={() => this.setState({ showImageModal: false })}
                >
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={this.state.imageModalImage.data} alt=""
                                    style={{ display: 'flex', justifySelf: 'center', maxWidth: "100%", maxHeight: '100%', flex: 0 }} />
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Col xs='auto'>
                                <Button onClick={() =>
                                    BackendService.deleteAusruestungFile(this.state.imageModalImage.preview.id).then(() => {
                                        this.props.refresh()
                                    })}>
                                    Löschen</Button>
                            </Col>
                            <Col xs='auto'>
                                <Button onClick={() =>
                                    BackendService.editAusruestungFile(this.state.imageModalImage.preview.id, { mainImage: true }).then(() => {
                                        this.props.refresh()
                                    })}>
                                    In der Liste Verwenden</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>


            </Form>

        )
    }
}

export default FormAusruestung