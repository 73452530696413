import React from 'react';

import BackendService from '../../../BackendService.js';

import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import CustomTable from '../../../CustomTable/CustomTable.js'
import ChecklistForm from './ChecklistFormPrePost.js'
import CommentForm from './CommentForm.js'

import ChecklistView from './BaseChecklistView.js'

import _ from 'lodash';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class ChecklistViewPrePost extends ChecklistView {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleChangeCheckbox2 = this.handleChangeCheckbox2.bind(this);

        this.emptyNewElement = {
            "va": this.props.id,
            "list_typ": this.props.listTyp,
            "erstellt_von": this.props.user,
            "bez": "",
            "datum": null,
            "datum_bis": null,
            "zu_element": undefined,
            "aufgabe": "",
            "aktiv": true,
            "erledigt": false,
            "zustaendig": { benutzer_id: '' },
        }

        this.blankElement = {
            "id": 0,
            "va": 0,
            "list_typ": 0,
            "dt_erstellt": null,
            "erstellt_von": 0,
            "bez": "",
            "datum": null,
            "aufgabe": "",
            "erledigt_von": 0,
            "aktiv": false,
            "zustaendig": 0,
            "erledigt": false,
        }

        this.state = {
            checklistItems: [],
            groupedChecklistItems: [],
            selectedElement: this.blankElement,
            origElement: this.blankElement,
            newElement: this.emptyNewElement,
            loading: true,
            creatingNew: false,
            bemerkungen: [],
            grouped: true,
            onlyUnchecked: false,
            includeInactive: false,
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        let data = {
            "id": this.state.selectedElement.id,
            "va": this.state.selectedElement.va,
            "list_typ": this.state.selectedElement.list_typ,
            "dt_erstellt": this.state.selectedElement.dt_erstellt,
            "erstellt_von": this.state.selectedElement.erstellt_von,
            "bez": this.state.selectedElement.bez,
            "datum": this.state.selectedElement.datum !== "" ? this.state.selectedElement.datum : null,
            "aufgabe": this.state.selectedElement.aufgabe,
            "bemerkung": this.state.selectedElement.bemerkung,
            "erledigt_von": this.state.selectedElement.erledigt_von,
            "sort": this.state.selectedElement.sort,
            "aktiv": this.state.selectedElement.aktiv,
            "checkmaster_inaktiv": this.state.selectedElement.checkmaster_inaktiv,
            "zustaendig": this.state.selectedElement.zustaendig.benutzer_id === '' ? null : this.state.selectedElement.zustaendig.benutzer_id,
            "zu_element": this.state.selectedElement.zu_element,
            "erledigt": this.state.selectedElement.erledigt,
            "erledigt_final": this.state.selectedElement.erledigt_final,
        }
        this.setState({
            loading: true
        })
        BackendService.editChecklistElement(data).then(response => {
            this.refresh(() => {
                if (this.state.selectedElement.id !== undefined) {
                    this.setState({
                        selectedElement: _.find(this.state.checklistItems, (item) => item.id = this.state.selectedElement.id)
                    })
                }
            });
        })
    }

    handleNew(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            creatingNew: false
        })
        let data = {
            ...this.state.newElement,
            zustaendig: this.state.newElement.zustaendig.benutzer_id === '' ? null : this.state.newElement.zustaendig.benutzer_id,
            "datum": this.state.newElement.datum !== "" ? this.state.newElement.datum : null,
        }
        BackendService.createChecklistElement(data).then(response => {
            this.refresh(() => {
                if (this.state.selectedElement.id !== undefined) {
                    this.setState({
                        selectedElement: _.find(this.state.checklistItems, (item) => item.id = response.id)
                    })
                }
            });
        })
    }

    handleChangeCheckbox2 = (e, key) => {
        let value = e.target.checked === true ? true : false
        this.setState({
            [key]: value
        })
    };

    render() {
        let disableForm = this.state.loading || this.state.selectedElement === undefined || this.state.selectedElement.id === undefined

        let userOptions = undefined;
        if (this.state.users !== undefined) {
            userOptions = [<option key={''} value={''} />]
            this.state.users.forEach(user => {
                userOptions.push(<option key={user.benutzer.benutzer_id} value={user.benutzer.benutzer_id}>{user.benutzer.nachname + ', ' + user.benutzer.vorname + ' (' + user.benutzer.kuerzel + ')'})</option>)
            });
        }

        let data = this.state.grouped ? this.state.groupedChecklistItems : this.state.checklistItems
        if (!this.state.includeInactive) {
            data = _.filter(data, (row) => row.aktiv)
        }
        if (this.state.onlyUnchecked) {
            data = _.filter(data, (row) => !row.erledigt)
        }

        return (
            <Container fluid>
                <Row>
                    <Col xs={8} style={{overflow: 'scroll'}}>
                        <Row>
                            <Col xs='auto'>
                                <Button onClick={() => this.setState({ creatingNew: true, newElement: this.emptyNewElement })}>+ Neuer Eintrag</Button>
                            </Col>
                            <Col xs='auto'>
                                <Button onClick={() => this.setState({ creatingNew: true, newElement: this.state.selectedElement })}>+ Eintrag kopieren</Button>
                            </Col>
                            <Col xs='auto' style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Group controlId="check_group" style={{ margin: 0 }}>
                                    <Form.Check checked={this.state.grouped} label="Gruppiert" onChange={(e) => { this.handleChangeCheckbox2(e, 'grouped') }} />
                                </Form.Group>
                            </Col>
                            <Col xs='auto' style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Group controlId="check_onlyUnchecked" style={{ margin: 0 }}>
                                    <Form.Check checked={this.state.onlyUnchecked} label="nur unerledigte" onChange={(e) => { this.handleChangeCheckbox2(e, 'onlyUnchecked') }} />
                                </Form.Group>
                            </Col>
                            {this.props.rights[13] !== undefined && <Col xs='auto' style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Group controlId="check_includeInactive" style={{ margin: 0 }}>
                                    <Form.Check checked={this.state.includeInactive} label="auch inaktive" onChange={(e) => { this.handleChangeCheckbox2(e, 'includeInactive') }} />
                                </Form.Group>
                            </Col>}
                        </Row>
                        <Row>
                            <CustomTable
                                initialSortKey={'sort'}
                                collumns={[
                                    {
                                        sortable: false,
                                        title: 'sort',
                                        key: 'sort',
                                        cell: (row, data, indentation) => {
                                            if (row.id === this.state.selectedElement.id && indentation === 0) {
                                                return (
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                        <Button style={{ backgroundColor: "transparent", border: "transparent", padding: "0px" }}
                                                            onClick={() => this.sort(row, data, -1)}
                                                        ><FontAwesomeIcon icon="caret-up" size="2x" /></Button>

                                                        <Button style={{ backgroundColor: "transparent", border: "transparent", padding: "0px" }}
                                                            onClick={() => this.sort(row, data, 1)}
                                                        ><FontAwesomeIcon icon="caret-down" size="2x" /></Button>
                                                    </div>
                                                )
                                            }
                                        },
                                    },
                                    {
                                        sortable: false,
                                        title: 'eingest.',
                                        key: 'dt_erstellt',
                                        cell: row =>
                                            <span key={row.id + '_dt_erstellt'}>{row.dt_erstellt !== undefined ? moment(row.dt_erstellt).format("DD.MM.YYYY") : ''} </span>
                                    },
                                    {
                                        sortable: false,
                                        title: 'von',
                                        key: 'erstellt_von'
                                    },
                                    {
                                        sortable: false,
                                        title: 'Stichwort',
                                        key: 'bez'
                                    },
                                    {
                                        sortable: false,
                                        title: 'bis wann',
                                        key: 'datum',
                                        cell: row =>
                                            <span key={row.id + '_datum'}>{row.datum !== undefined ? moment(row.datum.substring(0, 19)).format("DD.MM.YYYY") : ''} </span>
                                    },
                                    {
                                        sortable: false,
                                        title: 'zuständig',
                                        key: 'zustaendig',
                                        cell: row =>
                                            <span key={row.id + 'zustaendig'}>{row.zustaendig !== undefined && row.zustaendig.benutzer_id !== '' ? row.zustaendig.nachname + ' ' + row.zustaendig.kuerzel : '-'}</span>,
                                    },
                                    {
                                        sortable: false,
                                        title: 'erl.',
                                        key: 'erledigt',
                                        cell: row =>
                                            <input key={row.id + 'erledigt'} type="checkbox" disabled checked={row.erledigt} />
                                    },
                                    {
                                        sortable: false,
                                        title: 'aktiv',
                                        key: 'aktiv',
                                        cell: row =>
                                            <input key={row.id + 'aktiv'} type="checkbox" disabled checked={row.aktiv} />
                                    },
                                ]}
                                data={data}
                                onSelect={(row) => {
                                    this.handleSelect(row)
                                }}
                            />
                        </Row>

                    </Col>
                    <Col xs={4} style={{ padding: '30px' }}>
                        <Row>
                            <ChecklistForm disabled={disableForm}
                                userOptions={userOptions}
                                handleSubmit={this.handleSubmit}
                                handleChange={(e) => this.handleChange(e, 'selectedElement')}
                                handleChangeCheckbox={(e) => this.handleChangeCheckbox(e, 'selectedElement')}
                                handleNewSubElement={(parentID) => this.setState({ creatingNew: true, newElement: { ...this.emptyNewElement, zu_element: parentID } })}
                                selectedElement={this.state.selectedElement}
                                origElement={this.state.origElement}
                            />
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <span>wichtige Bemerkungen</span>
                                    <Button style={{ marginLeft: 'auto' }} onClick={() => this.setState({ newComment: true })}>neue Bemerkung</Button>
                                </Row>
                                <Row>
                                    <CustomTable
                                        hideSearch
                                        collumns={[
                                            {
                                                title: 'von.',
                                                key: 'ersteller',
                                            },
                                            {
                                                title: 'Datum / Uhrzeit',
                                                key: 'dterstellt',
                                                cell: row =>
                                                    <span key={row.id + '_datum'}>{row.dterstellt !== undefined ? moment(row.dterstellt).format("DD.MM.YYYY") : ''}</span>
                                            },
                                            {
                                                title: 'Bemerkung.',
                                                key: 'bemerkung',
                                            },
                                        ]}
                                        data={this.state.bemerkungen}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Modal
                    show={this.state.creatingNew}
                    centered
                    onHide={() => this.setState({ creatingNew: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Neues Element
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ChecklistForm
                            new
                            userOptions={userOptions}
                            disabled={false}
                            handleSubmit={this.handleNew}
                            handleChange={(e) => this.handleChange(e, 'newElement')}
                            handleChangeCheckbox={(e) => this.handleChangeCheckbox(e, 'newElement')}
                            selectedElement={this.state.newElement}
                        />
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.newComment}
                    centered
                    onHide={() => this.setState({ newComment: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Neue Bemerkung
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CommentForm id={this.state.selectedElement.id} close={() => {
                            this.setState({
                                newComment: false
                            })
                            BackendService.getBemerkungen(this.state.selectedElement.id).then(
                                bemerkungen => {
                                    bemerkungen.map(bemerkung => bemerkung.key = bemerkung.id)
                                    this.setState({
                                        bemerkungen
                                    })
                                }
                            )
                        }} />
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        id: state.vaView.selectedVA,
        user: state.userData.user,
        rights: state.userData.rights,
    }
}

export default connect(mapStateToProps)(ChecklistViewPrePost)