import { Component } from 'react';

import _ from 'lodash';

class BaseForm extends Component {

    constructor(...args) {
        super(...args);
        this.default = {
            selected: {
                "id": 0,
            }
        }
    }

    handleChange = (e) => {
        let key = e.target.id;
        let value = e.target.value
        this.modifySelected(key,value);   
        console.log('change')     
    };

    handleChangeCheckbox = (e) => {
        let key = e.target.id;
        let value = e.target.checked === true ? true : false
        this.modifySelected(key,value);
    };

    modifySelected = (key,value) => {
        let keys = key.split('.');
        let prevSelected = _.cloneDeep(this.state.selected)
        this.setDeepChild(prevSelected, keys, value)
        this.setState(prevState => (
            {
                selected: prevSelected
            }))
    }

    setDeepChild(obj, path, value) {
        let ret = obj
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {}
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value
        return ret;
    }

    reset(){
        this.setState({
            selected: {
                ...this.default.selected,
                ...this.props.selected
            }
        })
    }

    render() {
        return;
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected) {
            this.reset();
        }
    }
}

export default BaseForm