import {SET_NAVIGATION_ROUTE, SET_USERDATA, LOGOUT, SET_VA_SELECTED} from './actionTypes';

export const setNavigationRoute = (value) => ({
    type:SET_NAVIGATION_ROUTE,
    value:value
})

export const setUserData = (value) => ({
    type:SET_USERDATA,
    value:value
})

export const logout = (value) => ({
    type:LOGOUT,
    value:value
})

export const setVaSelected = (value) => ({
    type:SET_VA_SELECTED,
    value:value
})