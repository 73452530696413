import axios from 'axios';

//const apiBaseURL = 'http://127.0.0.1:8080/api';
const apiBaseURL = 'http://steve.lightsail-aws.qmarketing.de/api';
//const apiBaseURL = 'http://192.168.1.117:8080/api';

export default class BackendService {

    static getAuthToken() {
        return sessionStorage.getItem('authToken');
    }
    //AUTH
    static authenticate(user, pw) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + '/auth',
                    method: 'post',
                    data: {
                        'benutzername': user,
                        'passwort': pw
                    }
                }
            )
                .then(response => {
                    sessionStorage.setItem('authToken', response.data.auth_token);
                    sessionStorage.setItem('userName', user);
                    resolve(user);
                })
                .catch(error => reject(error));
        });

    }

    static changePassword(data) {
        return BackendService.patch('/auth', data);
    }

    //VA
    static getVeranstaltungen(id = '') {
        return BackendService.get('/veranstaltung/' + id);
    }

    static editVeranstaltungen(data) {
        return BackendService.put('/veranstaltung', data);
    }

    static createVeranstaltungen(data) {
        return BackendService.post('/veranstaltung', data);
    }

    static getVAArten() {
        return BackendService.get('/veranstaltungsarten');
    }

    static getVATypen() {
        return BackendService.get('/veranstaltungstypen');
    }

    static getVAUsers(id) {
        return BackendService.get('/veranstaltungsbenutzer/' + id);
    }

    static createVAUser(va, id) {
        return BackendService.post('/veranstaltungsbenutzer', { va: va, benutzer: id, sort: null, rausgenommen: null });
    }

    static deleteVAUser(va, id) {
        return BackendService.delete('/veranstaltungsbenutzer/' + va + '/' + id);
    }

    //User
    static getUsers() {
        return BackendService.get('/user');
    }

    static createUser(data) {
        return BackendService.post('/user', data);
    }

    static editUser(data) {
        return BackendService.put('/user', data);
    }

    static deleteUser(id) {
        return BackendService.delete('/user', { benutzer_id: id });
    }

    //Checklist
    static getChecklistElements(va, typ) {
        return BackendService.get('/checklistelements/' + va + '/' + typ);
    }

    static editChecklistElement(data) {
        return BackendService.put('/checklistelements', data);
    }

    static createChecklistElement(data) {
        return BackendService.post('/checklistelements', data);
    }

    static getBemerkungen(id) {
        return BackendService.get('/checklistelement/' + id + '/bemerkungen');
    }

    static createBemerkung(id, data) {
        return BackendService.post('/checklistelement/' + id + '/bemerkungen', data);
    }

    //Beteiligte
    static getBeteiligte(va) {
        return BackendService.get('/beteiligte/veranstaltung/' + va);
    }

    static getBeteiligterById(id) {
        return BackendService.get('/beteiligte/' + id);
    }

    static deleteBeteiligter(data) {
        return BackendService.delete('/beteiligter', data);
    }

    static editBeteiligter(data) {
        return BackendService.put('/beteiligter', data);
    }

    static createBeteiligter(va, data) {
        return BackendService.post('/beteiligte/' + va, data);
    }

    static sortBeteiligte(va, body) {
        return BackendService.put('/veranstaltung/' + va + '/beteiligte/sort', body);
    }
    //Beteiligte File
    static getBeteiligterFile(id) {
        return BackendService.getImage('/beteiligte/' + id + '/preview');
    }

    static createBeteiligterFile(id, data) {
        return BackendService.post('/beteiligte/' + id + '/preview', data);
    }

    static deleteBeteiligterFile(id, data) {
        return BackendService.delete('/beteiligte/' + id + '/preview', data);
    }

    //Werbemittel
    static getWerbemittelByVA(va) {
        return BackendService.get('/wmittel/veranstaltung/' + va);
    }

    static createWerbemittel(data) {
        return BackendService.post('/wmittel', data);
    }

    static editWerbemittel(data) {
        return BackendService.put('/wmittel', data);
    }

    static deleteWerbemittel(data) {
        return BackendService.delete('/wmittel', data);
    }

    static copyWerbemittel(id) {
        return BackendService.copy('/wmittel/' + id);
    }
    //Werbemittel File
    static getWerbemittelFile(index) {
        return BackendService.getImage('/wmittel/preview/' + index);
    }

    static createWerbemittelFile(id, data) {
        return BackendService.post('/wmittel/' + id + '/preview', data);
    }

    static deleteWerbemittelFile(id) {
        return BackendService.delete('/wmittel/preview/' + id);
    }

    static editWerbemittelFile(id, data) {
        return BackendService.put('/wmittel/preview/' + id, data);
    }

    //Ausruestung
    static getAusruestungByVA(va) {
        return BackendService.get('/ausruestung/veranstaltung/' + va);
    }

    static createAusruestung(data) {
        return BackendService.post('/ausruestung', data);
    }

    static editAusruestung(data) {
        return BackendService.put('/ausruestung', data);
    }

    static deleteAusruestung(data) {
        return BackendService.delete('/ausruestung', data);
    }

    static copyAusruestung(id) {
        return BackendService.copy('/ausruestung/' + id);
    }
    //Ausruestung File
    static getAusruestungFile(index) {
        return BackendService.getImage('/ausruestung/preview/' + index);
    }

    static createAusruestungFile(id, data) {
        return BackendService.post('/ausruestung/' + id + '/preview', data);
    }

    static deleteAusruestungFile(id) {
        return BackendService.delete('/ausruestung/preview/' + id);
    }

    static editAusruestungFile(id, data) {
        return BackendService.put('/ausruestung/preview/' + id, data);
    }

    //Checkmaster
    static getCheckmasterElementsByListType(type, VAType) {
        return BackendService.get('/checkmaster/listentyp/' + type);
    }
    static createCheckmasterElement(data) {
        return BackendService.post('/checkmaster', data);
    }
    static editCheckmasterElement(data) {
        return BackendService.put('/checkmaster', data);
    }
    static deleteCheckmasterElement(id) {
        return BackendService.delete('/checkmaster', { id });
    }

    //Groups
    static getGroups() {
        return BackendService.get('/gruppen')
    }

    //Roles
    static getRoles() {
        return BackendService.get('/rollen')
    }

    //Help
    static getHelp() {
        return BackendService.get('/help')
    }

    static editHelp(data) {
        return BackendService.put('/help', data)
    }

    //FAQ
    static getFAQ(){
        return BackendService.get('/faq')
    }

    static createFAQ(data){
        return BackendService.post('/faq',data)
    }

    static editFAQ(data){
        return BackendService.put('/faq',data)
    }

    static deleteFAQ(id){
        return BackendService.delete('/faq',{id: id})
    }

    //____________
    static post(route, body) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'post',
                    data: body,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                }
            )
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static get(route, config) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    ...config,
                    url: apiBaseURL + route,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static delete(route, data) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                    data
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static put(route, body) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                    data: body
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static copy(route, config) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    ...config,
                    url: apiBaseURL + route,
                    method: 'copy',
                    headers: {
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static patch(route, body) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'patch',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                    data: body
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static getImage(route) {
        return new Promise((resolve, reject) => {
            BackendService.get(route, { responseType: 'blob' }).then(data => {
                var reader = new FileReader();
                reader.onload = function () {
                    var b64 = reader.result.replace(/^data:.+;base64,/, '');
                    resolve('data:image/jpeg;base64,' + b64);
                };
                reader.readAsDataURL(data);
            }).catch(error => {
                reject(error);
            });
        });
    }

}