import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import BackendService from './BackendService.js';
import { setUserData } from './actions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styles from './Login.module.css'

const required = value => (value ? undefined : 'Required')

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(loginData) {
        BackendService.authenticate(loginData.user, loginData.password)
            .then(
                user => {
                    this.props.dispatch(setUserData({ user: user }))
                })
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
    }

    render() {
        // {[styles., styles., ''].join(' ')}
        return (
            <Container>
                <Row>
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className={[styles.cardSignin, 'card', 'my-5'].join(' ')}>
                            <div className={styles.cardBody}>
                                <h5 className={[styles.cardTitle, 'text-center'].join(' ')}>Sign In</h5>
                                <Form onSubmit={this.onSubmit}
                                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                                        <form onSubmit={handleSubmit} className={styles.formSignin}>
                                            <Field name="user" validate={required}>
                                                {({ input, meta }) => (
                                                    <div className={styles.formLabelGroup}>
                                                        <label htmlFor="username">Benutzername</label>
                                                        <input className={styles.myInput} {...input} type="username" id="inputuUsername" className="form-controll" placeholder="Benutzername" required autoFocus />
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="password" validate={required}>
                                                {({ input, meta }) => (
                                                    <div className={styles.formLabelGroup}>
                                                        <label htmlFor="inputPassword">Passwort</label>
                                                        <input className={styles.myInput} {...input} type="password" id="inputPassword" className="form-controll" placeholder="Passwort" required />
                                                    </div>
                                                )}
                                            </Field>
                                            <button className={[styles.btn, "btn-lg btn-primary btn-block text-uppercase"].join(' ')} type="submit">Sign in</button>
                                            <hr className="my-4" />
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                </Row>
            </Container>
        )
    }
}

const mapStateToPropsSeries = (state) => {
    return {

    }
}

export default connect(mapStateToPropsSeries)(Login)