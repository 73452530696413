import React, { Component } from 'react';
import BackendService from '../../BackendService.js';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CustomTable from "../../CustomTable/CustomTable";

import EventForm from './EventForm'
import Modal from 'react-bootstrap/Modal'


import { connect } from 'react-redux';
import { setVaSelected, setNavigationRoute } from '../../actions';
import moment from 'moment'
import _ from 'lodash';

class ViewEvents extends Component {

    constructor() {
        super();

        this.onNewCallback = this.onNewCallback.bind(this);

        this.state = {
            data: [],
            showActive: true,
            showInactive: false,
            selectedType: 0,
            types: [],
            loading: true,
        }
        Promise.all([
            BackendService.getVeranstaltungen().then(
                VAs => {
                    VAs.forEach(VA => {
                        VA.art_bez = VA.art.bez
                        VA.key = VA.id
                    });
                    this.setState({
                        data: VAs
                    })
                }
            ),
            BackendService.getVATypen().then(
                typen => {
                    let activeTypes = []
                    typen.forEach(type => {
                        if (type.aktiv) {
                            activeTypes.push(type)
                        }
                    });
                    this.setState({
                        types: activeTypes,
                        selectedType: activeTypes[0].id + ''
                    })
                }
            )]
        ).then(() => {
            this.setState({
                loading: false,
            })
        })

        this.columns = [
            {
                title: 'Name',
                key: 'bez',
                sortable: true
            },
            {
                title: 'Art',
                key: 'art_bez',
                sortable: true
            },
            {
                textitlet: 'Ort',
                key: 'ort',
                sortable: true
            },
            {
                title: 'von',
                key: 'von',
                cell: row =>
                    <span key={row.id + 'von'}>{row.von != null ? moment(row.von.substring(0, 10)).format("DD.MM.YYYY") : ''}</span>,
                sortable: true
            },
            {
                title: 'bis',
                key: 'bis',
                sort: 'asc',
                cell: row =>
                    <span key={row.id + 'bis'}>{row.bis != null ? moment(row.bis.substring(0, 10)).format("DD.MM.YYYY") : ''}</span>,
                sortable: true
            }
            , {
                title: 'PaxKunden',
                key: 'anz_teilnehmer',
                sortable: true,
            }
            , {
                title: 'PaxBud',
                key: 'anz_teilnehmer_vertrieb',
                sortable: true,
            }
            , {
                title: 'PaxBudSC',
                key: 'anz_teilnehmer_mkt3',
                sort: 'asc',
                sortable: true,

            }
            , {
                title: 'Budget',
                key: 'budget',
                sortable: true,
            },
            {
                title: 'Bearbeiten',
                key: 'id',
                sortable: false,
                center: true,
                cell: row =>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }} key={row.id + '_Buttons'}>
                        <Button onClick={() => {
                            this.props.dispatch(setVaSelected(row.id))
                            this.props.dispatch(setNavigationRoute(this.props.routes.BEARBEITEN.EDIT_BETEILIGTE))
                        }}>Bearbeiten</Button>
                        {this.props.rights[1] !== undefined && <Button onClick={() => {
                            this.props.dispatch(setVaSelected(row.id))
                            this.props.dispatch(setNavigationRoute(this.props.routes.GRUNDDATEN))
                        }}>Grunddaten/Zugriffsrechte</Button>
                        }
                    </div >
            }
        ]
    }

    onNewCallback(va) {
        let vas = _.cloneDeep(this.state.data)
        vas.push(va)
        this.setState({
            creatingNew: false,
            data: vas
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={this.props.className}>
                    <span>Lade Veranstaltungen</span>
                </div>
            )
        }

        let typeOptions = []
        this.state.types.forEach(type => {
            typeOptions.push(<option key={type.id} value={type.id}>{type.bez}</option>)
        })


        let filteredData = this.state.data
        if (!this.state.showActive && !this.state.showInactive) {
            filteredData = []
        } else if (this.state.showActive !== this.state.showInactive) {
            filteredData = _.filter(filteredData, (entry) => { return entry.aktiv === this.state.showActive })
        }
        filteredData = _.filter(filteredData, (entry) => { return (entry.typ.id + '' === this.state.selectedType) })

        return (
            <div className={this.props.className}>

                <Form>
                    <Form.Row>
                        <Col xs="auto">
                            <Form.Group as={Row} controlId="vaType">
                                <Form.Label>
                                    Veranstaltungstyp:
                            </Form.Label>
                                <Col>
                                    <Form.Control
                                        as="select" value={this.state.selectedType}
                                        onChange={(e) => { this.setState({ selectedType: e.target.value }) }}
                                    >
                                        {typeOptions}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="showActive">
                                <Form.Check type="checkbox"
                                    label="aktive Veranstaltungen zeigen"
                                    checked={this.state.showActive}
                                    onChange={(e) => { this.setState({ showActive: e.target.checked === true ? true : false }) }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="showInactive">
                                <Form.Check type="checkbox"
                                    label="inaktive Veranstaltungen zeigen"
                                    checked={this.state.showInactive}
                                    onChange={(e) => { this.setState({ showInactive: e.target.checked === true ? true : false }) }} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Button onClick={() => { this.setState({ creatingNew: true }) }} disabled={this.props.rights[1] === undefined}>+ Neue Veranstaltungen</Button>
                        </Col>
                    </Form.Row>
                </Form>
                <CustomTable
                    collumns={this.columns}
                    data={filteredData}
                    onDoubleClick={
                        (row) => {
                            this.props.dispatch(setVaSelected(row.id))
                            this.props.dispatch(setNavigationRoute(this.props.routes.BEARBEITEN.EDIT_BETEILIGTE))
                        }
                    }
                />
                <Modal
                    size="xl"
                    show={this.state.creatingNew}
                    centered
                    onHide={() => this.setState({ creatingNew: false })}
                >
                    <Modal.Body>
                        <EventForm new onNewCallback={this.onNewCallback} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRoute: state.navigation.selectedTab,
        routes: state.navigation.routes.VERANSTALTUNGEN,
        rights: state.userData.rights,
    }
}

export default connect(mapStateToProps)(ViewEvents)