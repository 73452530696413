import React, { Component } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CheckmasterView from './CheckmasterView.js';

import _ from 'lodash';

class CheckmasterMain extends Component {

    render() {

        return (
            <div className={this.props.className}>

                <Tabs defaultActiveKey="pre" id="checkmaster-tabs">
                    <Tab eventKey='pre' title="Vorbereitung">
                        <CheckmasterView listTyp={1} va_type={10} />
                    </Tab>
                    <Tab eventKey='during' title="Durchführung">
                        <CheckmasterView listTyp={3} va_type={10} />
                    </Tab>
                    <Tab eventKey='post' title="Nachbereitung">
                        <CheckmasterView listTyp={4} va_type={10} />
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default CheckmasterMain
