import React, { Component } from 'react';

import BackendService from '../../BackendService.js';
import _ from 'lodash';

class BaseViewWerbemittel extends Component {

    constructor(props) {
        super(props);
        this.refresh();

        this.state = {
            data: [],
            selected: {
                id: 0
            },
            images: {}
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.refresh = this.refresh.bind(this);

        this.table = React.createRef();
    }

    refresh() {
        return new Promise((resolve) => {
            BackendService.getWerbemittelByVA(this.props.id).then((data) => {
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    element.key = element.id;
                    if (element.previews.length===0) {
                        continue;
                    }
                    for (let h = 0; h < element.previews.length; h++) {
                        let preview = element.previews[h];
                        if (preview.mainImage) {
                            BackendService.getWerbemittelFile(preview.id).then((data) => {
                                let prevImages = _.cloneDeep(this.state.images);
                                this.setDeepChild(prevImages, [element.key, preview.id], data);
                                this.setState({
                                    images: prevImages
                                });
                            });
                            break;
                        }                        
                    }
                }

                let selected = this.state.selected;
                if (selected.id !== 0) {
                    selected = _.find(data, (item) => item.id === selected.id);
                }
                this.setState({
                    data: data,
                    selected
                });
                resolve();
            });
        });
    }

    setDeepChild(obj, path, value) {
        let ret = obj;
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {};
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value;
        return ret;
    }

    handleEdit(wm) {
        wm.key = wm.id;
        let werbemittel = _.cloneDeep(this.state.data);
        let index = _.findIndex(werbemittel, (item) => item.id === wm.id);
        werbemittel[index] = wm;
        this.setState({
            data: werbemittel
        });
    }

    handleNew() {
        BackendService.createWerbemittel({artikel: 'Neuer Eintrag', beschreibunglang: '', artikelnummer: '', lagerort: '', lieferant: '', bemerkung: '', veranstaltungid: this.props.id, ausfuerung: '', groesse: '', logo: '', owner: '', beschaffer: ''}).then((wm) => {
            let werbemittel = _.cloneDeep(this.state.data);
            wm.key=wm.id;
            werbemittel.push(wm);
            this.setState({
                data: werbemittel,
                selected: wm
            });
            this.table.current.setSelectedRowKey(wm.id);
        });
    }

    handleDelete(id) {
        let werbemittel = _.cloneDeep(this.state.data) ;
        _.remove(werbemittel, (item) => item.id === id);
        this.setState({
            data: werbemittel,
            selected: {
                id: 0
            },
        });
        this.table.current.setSelectedRowKey(0);
    }

    render() {
        return;
    }
}

export default BaseViewWerbemittel;