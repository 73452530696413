import React from 'react';

import BackendService from '../../../BackendService.js';

import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import CustomTable from '../../../CustomTable/CustomTable.js';
import FormAusruestung from '../../Ausruestung/FormAusruestung';
import BaseViewAusruestung from '../../Ausruestung/BaseViewAusruestung';

import _ from 'lodash';

class ViewAusruestung extends BaseViewAusruestung {

    constructor(props) {
        super(props);

        this.state =
            {
                ...this.state,
                bestandData: [],
                selectedBestand: 0,
                bestandImages: {}
            };

        BackendService.getAusruestungByVA(0).then((data) => {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                element.key = element.id;
                if (element.previews.length === 0) {
                    continue;
                }
                for (let h = 0; h < element.previews.length; h++) {
                    let preview = element.previews[h];
                    if (preview.mainImage) {
                        BackendService.getAusruestungFile(preview.id).then((data) => {
                            let prevImages = _.cloneDeep(this.state.bestandImages);
                            this.setDeepChild(prevImages, [element.key, preview.id], data);
                            this.setState({
                                bestandImages: prevImages
                            });
                        });
                        break;
                    }
                }
            }

            this.setState({
                bestandData: data,
                selectedBestand: 0,
            });
        });

        this.collumns =
            [
                {
                    title: 'Art.-Nr.',
                    key: 'artikelnummer',
                },
                {
                    title: 'Artikel',
                    key: 'artikel',
                },
                {
                    title: 'Artikelausführung',
                    key: 'ausfuerung',
                },
                {
                    title: 'Größe HxBxT',
                    key: 'groesse',
                },
                {
                    title: 'Logo',
                    key: 'logo',
                },
                {
                    title: 'Lagerort',
                    key: 'lagerort',
                },
                {
                    title: 'Artikel Owner',
                    key: 'owner',
                },
                {
                    title: 'Beschaffer',
                    key: 'beschaffer',
                },
                {
                    title: 'Artikel in STEVE angelegt von',
                    key: 'angelegt_von',
                },
                {
                    title: 'Stückzahl',
                    key: 'stueckzahl',
                },
                {
                    title: 'Bild',
                    key: 'previews',
                    cell: (row) => {
                        let images = this.state.selectingFromBestand ? this.state.bestandImages[row.key] : this.state.images[row.key]
                        if (images !== undefined) {
                            let srcID = _.find(row.previews, (preview) => preview.mainImage).id
                            return (
                                <div style={{ alignItems: "center", display: "flex", justifyContent: 'center', overflow: 'hidden' }}>
                                    <img
                                        src={images[srcID]} alt=""
                                        style={{ maxHeight: "100px", maxWidth: "100px" }}
                                    />
                                </div>
                            )
                        }
                    }
                }
            ]
        this.handleCopy = this.handleCopy.bind(this);
    }

    handleCopy() {
        let va = this.props.id;
        BackendService.copyAusruestung(this.state.selectedBestand.id).then((wm) => {
            wm.veranstaltungid = va
            BackendService.editAusruestung(wm).then(()=>{
                this.refresh()
                this.setState({selectingFromBestand: false})
            })
        })
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Button onClick={this.handleNew}>Neuer Eintrag</Button>
                        <Button onClick={() => this.setState(prev => ({ selectingFromBestand: !prev.selectingFromBestand }))}>
                            {this.state.selectingFromBestand ? <span>zur Packliste zurückkehren</span> : <span>Aus Bestandsliste Auswählen</span>}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8} style={{overflow: 'scroll'}}>
                        <CustomTable
                            ref={this.table}
                            initialSortKey={'sort'}
                            collumns={this.collumns}
                            data={this.state.selectingFromBestand ? this.state.bestandData : this.state.data}
                            onSelect={(row) => {
                                this.setState(this.state.selectingFromBestand ? { selectedBestand: row } : { selected: row })
                            }}
                        />
                    </Col>
                    <Col xs={4}>
                        {!this.state.selectingFromBestand &&
                            <FormAusruestung
                                va={this.props.id}
                                disabled={this.state.selected.id === 0}
                                selected={this.state.selected}
                                refresh={this.refresh}
                                handleDelete={this.handleDelete}
                                handleEdit={this.handleEdit}
                            />
                        }
                        {this.state.selectingFromBestand &&
                            <FormAusruestung
                                va={0}
                                selected={this.state.selectedBestand}
                                refresh={this.refresh}
                                selectionOnly
                                disabled
                                onCopy={this.handleCopy}
                            />
                        }
                    </Col>
                </Row>

            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        id: state.vaView.selectedVA
    }
}

export default connect(mapStateToProps)(ViewAusruestung)