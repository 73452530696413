import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PDF1 from '../../Docs/Bedienungsanleitung.pdf'
import PDF2 from '../../Docs/Suchfunktion.pdf'
import PDF3 from '../../Docs/steve_einfuehrung.pdf'

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class ViewHome extends Component {

    render() {
        //let text = '<TEXTFORMAT LEADING="2"><P ALIGN="LEFT"><FONT FACE="Arial" SIZE="11" COLOR="#000000" LETTERSPACING="0" KERNING="0">Bitte bei <B>neuen Einträge</B> unbedingt prüfen, ob der entsprechende Punkt schon evtl. in einer ähnlichen Form vorhanden ist. Dann sinnvoll zusammenfassen.</FONT></P></TEXTFORMAT><TEXTFORMAT LEADING="2"><P ALIGN="LEFT"><FONT FACE="Arial" SIZE="11" COLOR="#000000" LETTERSPACING="0" KERNING="0">(Simone Schaum, 15.3.2010)</FONT></P></TEXTFORMAT>'
        return (
            <div className={this.props.className}>
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <img src="/imgs/willkommen.png" alt='' />
                            </Row>
                            <Row>
                                <p style={{ marginRight: '20px' }}>Herzlich Willkommen bei steve - unserer gemeinsamen Planungs-Plattform für alle STARCLUB-Veranstaltungen. Mit steve werden wir künftig unsere STARCLUB-Events noch effizienter und für alle zeitsparender auf die Beine stellen. Viel Erfolg bei der Arbeit mit steve. <br /><br /> Ihr STARCLUB-Team</p>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/petersen.gif" alt='Martin Petersen' />
                                    </Row><Row>
                                        <label>Martin Petersen</label>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/groebe.gif" alt='Claudia Gröbe' />
                                    </Row><Row>
                                        <label>Claudia Gröbe</label>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/schubert.gif" alt='Kirstin Schubert' />
                                    </Row><Row>
                                        <label>Kirstin Schubert</label>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/becker.jpg" alt='Michael Becker' />
                                    </Row><Row>
                                        <label>Michael Becker</label>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/weishaupt.jpg" alt='Janette Weishaupt' />
                                    </Row><Row>
                                        <label>Janette Weishaupt</label>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/gorschlueter.jpg" alt='Laura Gorschlüter' />
                                    </Row><Row>
                                        <label>Laura Gorschlüter</label>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <img src="/imgs/signatures/koch.png" alt='Tina Koch' />
                                    </Row><Row>
                                        <label>Tina Koch</label>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <a href={PDF1} target="_blank" rel="noopener noreferrer"><img src="/imgs/bedienungsanleitung_steve_160810.jpg" alt='bedienungsanleitung_1' /></a>
                            </Row>
                            <Row>
                                <a href={PDF2} target="_blank" rel="noopener noreferrer"><img src="/imgs/bedienungsanleitung_steve_231110.jpg" alt='bedienungsanleitung_2' /></a>
                            </Row>
                            <Row>
                                <a href={PDF3} target="_blank" rel="noopener noreferrer"><img src="/imgs/dasErsteMal.jpg" alt='Das erste Mal hier?' /></a>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row>
                        <CKEditor
                            editor={ClassicEditor}
                            data={text}
                            config={{
                                toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
                            }}
                            onInit={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log(Array.from(editor.ui.componentFactory.names()))
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    </Row> */}
                </Container>

            </div >
        )
    }
}

export default ViewHome