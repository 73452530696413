import React from 'react';
import { Provider } from 'react-redux'
import store from './app/store/index'
import AuthGate from './app/AuthGate.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltDown, faLongArrowAltUp, faCaretDown, faCaretUp, faCaretRight, faTasks, faEnvelopeOpenText, faLightbulb, faQuestionCircle, faPrint} from '@fortawesome/free-solid-svg-icons'
import {faClock} from '@fortawesome/free-regular-svg-icons'
 

import './App.css';

library.add(faLongArrowAltDown, faLongArrowAltUp, faCaretDown, faCaretUp, faCaretRight, faTasks, faClock, faEnvelopeOpenText, faLightbulb, faQuestionCircle, faPrint)

function App() {
  return (
    <Provider store={store}>
      <AuthGate/>
    </Provider>
  );
}

export default App;
